
import { apiRequest } from '@/utils/api'
import { proxyRequest } from '@/utils/fetcher'
import { lookupRequest } from '@/utils/lookup'
import GeneralUser from '../../utils/providers/generalUser'
import restrictions from '../../config/restrictions'
import { deepDiffArray } from '@/utils/deepDiffObjects'
import { notify } from '@kyvg/vue3-notification'
import papaparse from 'papaparse'

import config from '@/config'
export default {
  namespaced: true,
  state: {
    count: 0,
    providers: [],
    ipInsights: {},
    hlrs: {},
    imsi: {},
    location: {},
    phishing: {},
    providerStatus: {
      email: {},
      phone: {},
      nickname: {},
      domain: {},
      name: {}
    },
    lastTgUsers: [],
    faceSearchResult: {},
    similarSearchResult: {},
    phishingLoaded: false,
    phishingProblem: false,
    total: 0,
    email: {},
    phone: {},
    nickname: {},
    domain: {},
    name: {},
    accounts: {},
    imageInfoList: {},
    webSources: [],
    articles: [],
    notifies: [],
    shownArticles: [],
    articleFilters: [],
    usersSince: 0,
    notifiesLoaded: [],
    notifiesStatsLoaded: [],
    isLoaded: {
      phishing_create: true,
      manual: true,
      faq: true,
      face_search_services: true,
      similar_search_services: true
    },
    profilesCount: null
  },
  getters: {
    nickList: state => state.list,
    isLoaded: state => state.isLoaded,
    profilesCount: state => state.profilesCount === 0,
    allProviders: state => state.providers,
    allAccounts: state => state.accounts,
    faceSearchResult: state => state.faceSearchResult,
    similarSearchResult: state => state.similarSearchResult,
    usedProviders: state => ({ type, search }) => state.providerStatus[type][search] || []
  },
  mutations: {
    increment (state) {
      state.count++
    },
    reset (state) {
      state.count = 0
    },
    setTotal (state, n) {
      state.total = n
    },
    clearSearch (state) {
      state.providerStatus = {}
      state.list = {}
    },
    setUsersSince (state, payload) {
      state.usersSince = payload
    },

    addArticle (state, payload) {
      state.articles[payload.id] = payload
    },
    loadArticles (state, payload) {
      state.shownArticles = payload
    },
    loadNotifies (state, payload) {
      state.notifies = payload
    },
    loadNotifiesStats (state, payload) {
      state.notifiesStatsLoaded[payload.id] = payload
    },
    loadFilters (state, payload) {
      state.articleFilters = payload
    },
    addInsight (state, payload) {
      state.ipInsights[payload.ip] = payload.data
    },
    addHlr (state, payload) {
      state.hlrs[payload.phone] = payload.data
    },
    addImsi (state, payload) {
      state.imsi[payload.phone] = payload.data
    },
    addLocation (state, payload) {
      state.location[payload.phone] = payload.data
    },
    addResult (state, { search, type, object }) {
      if (!search || !type) return
      if (!state[type][search]) {
        state[type][search] = []
      }
      state[type][search].push(object)
    },
    updateProviders: (state, providers) => {
      state.providers = providers
    },
    phishingProblem: (state) => {
      state.phishingProblem = true
    },
    phishingLoaded: (state) => {
      state.phishingLoaded = true
    },
    clearTrigger: (state, payload) => {
      state.notifiesStatsLoaded[payload] = { total: 0, today: 0, id: payload }
    },
    faceSearchResult: (state, payload) => {
      state.faceSearchResult = payload
    },
    similarSearchResult: (state, payload) => {
      state.similarSearchResult = payload
    },
    imageInfo: (state, payload) => {
      state.imageInfoList[payload.key] = payload.payload
    },
    addFishing: (state, payload) => {
      state.phishing = payload
    },
    tgLastUsers: (state, payload) => {
      state.lastTgUsers = payload
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    profilesCount: (state, count) => {
      state.profilesCount = count
    },
    clearSearchResults: (state, {type, search}) => {
      delete state[type][search]
    },
    providerStatus: (state, { name, status, search, description, type }) => {
      let index = -1
      if (!state.providerStatus[type][search]) state.providerStatus[type][search] = []
      else index = state.providerStatus[type][search].findIndex(el => el.name === name)
      description = index > -1 ? state.providerStatus[type][search][index].description : description
      state.providerStatus[type][search][[index > -1 ? index : state.providerStatus[type][search].length || 0]] = { name, status, description }
    },
    fetchAccounts: (state, { data, identity }) => {
      state.accounts[identity] = data
    }
  },
  actions: {
    fetchRelations (context, currentObject) {
      return apiRequest(`/tools/relations/list?object=${currentObject}`, 'GET', {}, config.servers.fetcher_proxy)
    },
    fetchNotifiesStats ({ commit, state, dispatch, rootState }, id) {
      if (!state.notifiesStatsLoaded[id]) commit('startLoad', 'notifies_stats_' + id)
      proxyRequest(`/web/notifies/` + id + '/stat').then((resp) => {
        resp.data.id = id
        commit('loadNotifiesStats', resp.data)
      }).catch(() => {
      }).finally(function () {
        commit('stopLoad', 'notifies_stats_' + id)
      })
    },
    async getManual ({ commit }, { locale, type = 'manual' }) {
      commit('startLoad', type)
      const { data } = await apiRequest(`manuals/${type}link?lang=${locale}`, 'GET', {}, config.servers.tools)
      if (data && data.link) {
        const fileLink = document.createElement('a')
        fileLink.href = data.link
        let name = 'FAQ.pdf'
        if (type === 'manual') {
          name = config.manualTitle
          if (config.manualLanguages) {
            name = name.split('.pdf')[0] + `_${locale.toUpperCase()}.pdf`
          }
        }
        fileLink.setAttribute('download', name)
        document.body.appendChild(fileLink)
        fileLink.click()
        fileLink.remove()
      } else {
        console.error('Failed to get secure link')
      }
      commit('stopLoad', type)
    },
    async fetchNotifies ({ commit, state, dispatch, rootState }, { id, page }) {
      let { data } = await proxyRequest(`/web/notifies/` + id + '?page=' + page)
      data.items = data.items.map(el => {
        if (el.module === 'twitter') {
          el.stored.data = {
            created_at:  el.stored.data.created_at,
            id:  el.stored.data.id_str,
            lang:  el.stored.data.lang,
            place:  el.stored.data.place,
            source:  el.stored.data.source,
            tweet_text:  el.stored.data.full_text || el.stored.data.text,
            user_id:  el.stored.data.user.id_str,
            user: el.stored.data.user
          }
        }
        if (el.module === 'wa') {
          el.stored.data.timestamp = el.stored.data.timestamp * 1000
        }
        return el
      })
      commit('loadNotifies', data)
      return data
    },
    fetchFilters ({ commit, state, dispatch, rootState }) {
      commit('startLoad', 'filters')
      proxyRequest(`/web/sources/filters`).then((resp) => {
        commit('loadFilters', resp.data)
      }).catch(() => {
      }).finally(() => {
        commit('stopLoad', 'filters')
      })
    },
    async fetchSimilarImagesByFile ({ commit, state, dispatch, rootState }, { file, type }) {
      commit('startLoad', type + '_search_services')
      const req = type === 'face' ? 'search' : 'searchSame'
      let data = new FormData()
      data.append('name', 'image')
      data.append('file', file)
      return proxyRequest.bind({
        context: {
          method: 'POST',
          body: data
        }
      })(`/tools/images/${req}`, {'Content-Type' : 'multipart/form-data'}).then((resp) => {
        let result = {}
        result.payload = resp.data
        result.key = file
        commit(type + 'SearchResult', result)
      }).catch(() => {
      }).finally(function () {
        commit('stopLoad', type + '_search_services')
      })
    },
    async fetchSimilarImagesByUrl ({ commit, state, dispatch, rootState }, { imageUrl, type }) {
      commit('startLoad', type + '_search_services')
      const req = type === 'face' ? 'search' : 'searchSame'
      return proxyRequest(`/tools/images/${req}?url=${imageUrl}`).then((resp) => {
        let result = {}
        result.payload = resp.data
        result.key = imageUrl
        commit(type + 'SearchResult', result)
      }).catch(() => {
      }).finally(function () {
        commit('stopLoad', type + '_search_services')
      })
    },
    async addTrackActivity ({ commit, state, dispatch, rootState }, { id, type }) {
      commit('startLoad', `activity_${type}`)

      try {
        const link = `/${type}_services/telegram/?search=${encodeURIComponent(id)}`
        const { data: { data : data } } = await lookupRequest(link)

        if (!Array.isArray(data) || data.length === 0) {
          notify({ group: 'general', title: 'Error', text: 'User not found', type: 'error' })
          commit('stopLoad', `activity_${type}`)
          return false
        }

        let queryBody = {}

        if (type === 'nickname') {
          const index = data.findIndex(item => item.name && item.name.toLowerCase() === id.toLowerCase())
          if (index !== -1) {
            queryBody.username = id
            queryBody.tg_user_id = data[index].user_id
          }
          else {
            notify({ group: 'general', title: 'Error', text: 'User not found', type: 'error' })
            commit('stopLoad', `activity_${type}`)
            return false
          }
        } else {
          queryBody.tg_user_id = data[0].user_id
          queryBody.phone_number = id
        }

        await apiRequest('/tasks', 'POST', {}, config.servers.tgonline, queryBody)

        return true
      } catch {
        return false
      } finally {
        commit('stopLoad', `activity_${type}`)
      }
    },
    async deleteTrackedAccount ({ commit, state, dispatch, rootState }, { id }) {
      try {
        await apiRequest(`/tasks/${id}`, 'DELETE', {}, config.servers.tgonline)
        
        return true
      } catch {
        return false
      }
    },
    async downloadTrackedData ({ commit, state, dispatch, rootState }, { id, task }) {
      try {
        let csvData = []

        const { data } = await apiRequest(`/tasks/${id}/response`, 'GET', {}, config.servers.tgonline)

        if (!data.logs || data.logs.length === 0) {
          notify({ group: 'general', title: 'Warning', text: 'No activities tracked yet', type: 'warn' })
          return
        }

        csvData = [...data.logs].map(item => {
          return {
            user_id: item.raw_message.user_id, 
            timestamp: item.timestamp, 
            status: item.status, 
            comment: item.raw_message.comment, 
            was_online: item.raw_message.was_online
          }
        })

        const CSV = papaparse.unparse(csvData, {
          quotes: true,
          columns: ['user_id', 'timestamp', 'status', 'comment', 'was_online']
        })
  
        const blob = new Blob([CSV], {
          encoding: 'UTF-8',
          type: 'text/csv'
        })
  
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `tg_activity_tracker_${task}.csv`
        link.click()
  
        URL.revokeObjectURL(link.href)
        link.remove()

      } catch {
      }
    },
    async loadTrackedAccounts ({ commit, state, dispatch, rootState }) {
      commit('startLoad', `accountTracker`)

      try {
        const { data } = await apiRequest('/tasks', 'GET', {}, config.servers.tgonline)

        commit('stopLoad', `accountTracker`)
        return data
      } catch {

        commit('stopLoad', `accountTracker`)
        return []
      }
    },
    fetchImageInfo ({ commit, state, dispatch, rootState }, image) {
      commit('startLoad', 'imageInfo')
      return proxyRequest(`/tools/image_props?path=${image}`).then((resp) => {
        let result = {}
        result.payload = resp.data
        result.key = image
        commit('imageInfo', result)
      }).catch(() => {
      }).finally(function () {
        commit('stopLoad', 'imageInfo')
      })
    },
    fetchAvailableFishingLinks ({ commit, state, dispatch, rootState }) {
      if (!state.phishingLoaded) { commit('startLoad', 'phishing') }
      proxyRequest(`/fishnet/list`).then((resp) => {
        commit('addFishing', resp.data)
      }).catch(() => {
        commit('phishingProblem')
      }).finally(function () {
        commit('phishingLoaded')
        commit('stopLoad', 'phishing')
      })
    },
    createNewFishingLink ({ commit, state, dispatch, rootState }) {
      commit('startLoad', 'phishing_create')
      apiRequest(`/fishnet/create`, 'POST', {}, config.servers.fetcher_prox).then((resp) => {
      }).finally(function () {
        dispatch('fetchAvailableFishingLinks').finally(function () { commit('stopLoad', 'phishing_create') })
      })
    },
    fetchTgLastUsers ({ commit, state, dispatch, rootState }) {
      if (!state.isLoaded['last_tg_users']) { commit('startLoad', 'last_tg_users') }
      return new Promise((resolve, reject) => {
        proxyRequest(`/tools/last_tg_users?since=` + state.usersSince).then((resp) => {
          for (let i in resp.data) {
            if (resp.data[i].user) {
              commit('setUsersSince', resp.data[i].tgid)
              commit('tgcp/fetchAllUsers', [resp.data[i].user], { root: true })
            }
          }
          commit('tgLastUsers', resp.data)
          resolve('processed')
        }).finally(function () {
          commit('stopLoad', 'last_tg_users')
        })
      })
    },
    updateProviders ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'providers')
        lookupRequest('/services/')
          .then(res => {
            let userId = rootState.current_user_id
            if (restrictions[userId]) {
              if (restrictions[userId].module.indexOf('leak') > -1) {
                res.data = res.data.filter(provider => provider.name !== 'leak')
              }
            }
            commit('updateProviders', res.data)
            resolve()
          })
          .catch(err => reject(err))
          .finally(() => commit('stopLoad', 'providers'))
      })
    },
    async resolveSimilarMessageQuery ({ commit }, { chatId, messageId, similarity = 10 }) {
      const { data } = await proxyRequest(`/tools/tg_search_similar/${chatId}/${messageId}?similarity=${similarity}`)
      return data
    },
    async fetchAccounts ({ commit, state, dispatch }, { url, identity }) {
      commit('startLoad', 'accounts')
      try {
        let { data } = await proxyRequest(`/user_link_resolve?search=${url}&full_answer=true`)
        data = data.data.map(el => new GeneralUser(el))
        commit('fetchAccounts', { data, identity })
        return data
      } finally {
        commit('stopLoad', 'accounts')
      }
    },
    async cleanUpSearch ({ commit, state}, {search, type}) {
      commit('clearSearchResults', {type, search})
    },
    async fetch ({ commit, state, dispatch, rootGetters }, { search, providers, type = 'nickname', force = []}) {
      if (state[type][search] || !rootGetters.excludedSources) {
        return
      }
      await dispatch('updateProviders')
      if (!providers) providers = state.providers.filter(el => el.search_by.includes(type) && !rootGetters.excludedSources.includes(el.name))
      else providers = providers.map(el => state.providers.find(j => j.name === el))
      commit('reset')
      commit('setTotal', providers.length)
      providers.forEach(provider => commit('providerStatus', {
        name: provider.name,
        status: 'processing',
        description: provider.description,
        search,
        type
      }))
      for (var i in providers) {
        let providerName = providers[i].name
        let link = `/${type}_services/${providerName}/?search=${encodeURIComponent(search)}&full_answer=true`
        if (force.includes(providerName)) link += '&bypass=true'
        lookupRequest(link)
          .then(res => {
            try {
              const cached = res.data.cachedResult
              const archived = res.data.archivedResult ?? false
              var name = res.data.module
              var data = res.data.data
              var status = res.data.status
              if (status === 'ok') {
                if (data && data.length > 0) {
                  commit('providerStatus', {
                    name,
                    status: 'success',
                    search,
                    type
                  })
                } else {
                  commit('providerStatus', {
                    name,
                    status: 'nodata',
                    search,
                    type
                  })
                }
              } else {
                commit('providerStatus', {
                  name,
                  status: 'fail',
                  search,
                  type
                })
              }
              for (var j of data) {
                if (!j.type) j.type = name
                if (name === 'leetcode') {
                  j.raw = undefined
                }
                j.module = name
                j.cached = cached
                j.archived = archived
                const payload = {
                  search,
                  object: new GeneralUser(j),
                  type
                }
                commit('addResult', payload)
              }
            } catch {
              commit('providerStatus', { name, status: 'fail', type, search })
            }
          }).catch(() => {
            commit('providerStatus', { name: providerName, status: 'timeout', type, search })
          })
          .finally(() => {
            commit('increment')
          })
      }
    },
    async fetchCard ({ commit }, { provider, id }) {
      if (!id) throw new Error('Empty id')
      if (!provider) throw new Error('Empty provider')
      const { data } = await proxyRequest(`/cards/${provider}/${id}`)
      return data
    },
    async fetchHistoricalCard ({ commit }, { provider, id, source = {} }) {
      if (!id) throw new Error('Empty id')
      if (!provider) throw new Error('Empty provider')
      const { data } = await proxyRequest(`/cards/${provider}/${id}/historical`)
      return data.length ? deepDiffArray([ ...data, source ]) : []
    },
    fetchIpInsight ({ commit, state, dispatch, rootState }, ip) {
      if (state.ipInsights[ip]) {
        return
      }
      commit('startLoad', 'ip_' + ip)
      proxyRequest(`/tools/ipinsight/?search=` + ip)
        .then(resp => {
          var payload = {
            ip: ip,
            data: resp.data
          }
          commit('addInsight', payload)
        }).catch(() => {}).finally(() => {
          commit('stopLoad', 'ip_' + ip)
        })
    },
    fetchLocation ({ commit, state, dispatch, rootState }, phone) {
      commit('startLoad', 'location_' + phone)
      let rawPhone = phone
      phone = phone.replace('+', '').split(' ').join('')
      proxyRequest(`/phone_location_resolver?phone=` + encodeURIComponent(phone))
        .then(resp => {
          var data = resp.data
          var payload = {
            phone: rawPhone,
            data: data
          }
          commit('addLocation', payload)
        }).catch(() => {}).finally(() => {
          commit('stopLoad', 'location_' + rawPhone)
        })
    },
    fetchIMSI ({ commit, state, dispatch, rootState }, phone) {
      commit('startLoad', 'imsi_' + phone)
      proxyRequest(`/imsi_resolver?phone=` + encodeURIComponent(phone))
        .then(resp => {
          var data = resp.data
          var payload = {
            phone: phone,
            data: data
          }
          commit('addImsi', payload)
        }).catch(() => {}).finally(() => {
          commit('stopLoad', 'imsi_' + phone)
        })
    },
    fetchHlrInsight ({ commit, state, dispatch, rootState }, phone) {
      if (state.hlrs[phone]) {
        return
      }
      commit('startLoad', 'hlr_' + phone)
      proxyRequest(`/tools/phonehlr/?search=` + encodeURIComponent(phone))
        .then(resp => {
          var data = resp.data
          if (data.success) {
            data = data.results[0]
            delete data.id
            delete data.inserttime
            delete data.storage
            delete data.usercharge
            delete data.route
            delete data.interface
          } else {
            data = { success: false }
          }
          var payload = {
            phone: phone,
            data: data
          }
          commit('addHlr', payload)
        }).catch(() => {
          const data = { success: false }
          commit('addHlr', { phone, data })
        }).finally(() => {
          commit('stopLoad', 'hlr_' + phone)
        })
    },
    fetchIp ({ commit, state, dispatch }, email) {
      if (state.list[email]) {
        return
      }
      commit('reset')
      commit('setTotal', state.providers.ips ? state.providers.ips.length : 0)
      for (var i in state.providers.ips) {
        try {
          state.providers.ips[i].findByIp(email, dispatch).then(function (data) {
            data.providerId = i
            var dataList = [data]
            if (Array.isArray(data)) {
              dataList = data
            }
            for (var j in dataList) {
              var payload = {
                search_string: email,
                object: dataList[j]
              }
              if (payload.object.user_id || payload.object.name || payload.object.email) { commit('addResult', payload) }
            }
          }).finally(function () {
            commit('increment')
          })
        } catch (err) {
          commit('increment')
        }
      }
    }
  }
}
