<template>
  <div class="uk-card uk-card-default uk-card-body">
    <h3>
      {{ $t('whatsapp.tasks.add_task_title') }}
    </h3>
    <form @submit.prevent="submit">
      <Loader v-if="processing"/>
      <div v-else>
        <div class="uk-inline uk-width-1-1">
          <div
            v-if="!isGeo"
          >
            <input
              v-model.trim="task"
              type="text"
              :class="errors.validation"
              :placeholder="$t('twitter.tasks.username-or-hashtag')"
              class="uk-input"
            />
            <GeoButtonTask
              @selected="$e => task = $e"
            />
          </div>
          <div
            v-else
            class="uk-text-left"
            uk-grid
          >
            <div
              class="uk-width-2-3"
            >
              <div>
                <div
                  class="uk-text-bold"
                >
                  {{ $t('twitter.tasks.left-corner') }}:
                </div>
                {{ $t('twitter.tasks.geo.lat') }} {{ task[0].lat.toFixed(4) }};
                {{ $t('twitter.tasks.geo.lng') }} {{ task[0].lon.toFixed(4) }}
              </div>
              <div
                class="uk-margin-small-top"
              >
                <div
                  class="uk-text-bold"
                >
                  {{ $t('twitter.tasks.right-corner') }}::
                </div>
                {{ $t('twitter.tasks.geo.lat') }} {{ task[1].lat.toFixed(4) }};
                {{ $t('twitter.tasks.geo.lng') }} {{ task[1].lon.toFixed(4) }}
              </div>
            </div>
            <div
              class="uk-text-right uk-width-expand uk-inline"
            >
              <button
                class="uk-button uk-button-default uk-position-center-right"
                @click="task = ''"
              >
                {{ $t('twitter.tasks.clear') }}
              </button>
            </div>
          </div>
        </div>
        <textarea
          v-model="description"
          class="uk-textarea uk-margin-top"
          :placeholder="$t('twitter.tasks.task-description')"
        />
      </div>
      <div
        class="uk-margin-top uk-text-center"
      >
        <div>
          <a
            uk-icon="icon: warning; ratio: 1"
            :value="null"
            :class="priority ? 'uk-text-primary' : 'uk-text-muted'"
            :uk-tooltip="`title: ${$t('tgcp.tasks.priority_field_title')}`"
            @click="priority = !priority"
          />
          <a
            uk-icon="icon: bolt; ratio: 1"
            value="null"
            class="uk-margin-left"
            :uk-tooltip="'title: ' + $t('tgcp.tasks.real-time')"
            :class="validate.geo || realtime ? 'uk-text-primary' : 'uk-text-muted'"
            @click="realtime = !realtime"
          />
        </div>
      </div>
      <div class="uk-margin">
        <button
          class="uk-button uk-button-primary"
          type="submit"
        >
          {{ $t('twitter.tasks.submit') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { defineAsyncComponent } from 'vue'
import { userName as getTwitterUserName } from '@/utils/twitter'

import { mapActions } from 'vuex'
export default {
  name: 'TwitterAddTasks',
  components: {
    Loader,
    GeoButtonTask: defineAsyncComponent(() => import('@/components/Twitter/Tasks/GeoButtonTask.vue'))
  },
  props: {
    link: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      task: this?.link,
      description: '',
      priority: false,
      realtime: false,
      processing: false,
      errors: {
        validation: false
      }
    }
  },
  computed: {
    isGeo () {
      return !(!this.task || !Array.isArray(this.task) || this.task.length !== 2)
    }
  },
  methods: {
    ...mapActions('twitter', [
      'addTask'
    ]),
    validate (task = this.task) {
      if (!task || task === '') {
        this.$notify({ group: 'general', text: this.$t('twitter.tasks.empty-task'), type: 'error' })
        return null
      }
      if (Array.isArray(task)) {
        return { geo: task }
      } else {
        const parsed = getTwitterUserName(task)
        if (parsed) return { task: '@'.concat(parsed) }
        if (task.startsWith('@')) {
          if (!/^@?(\w){1,20}$/.test(task)) {
            this.$notify({ group: 'general', text: this.$t('twitter.tasks.spaces-error'), type: 'error' })
            return null
          }
        }
        return { task }
      }
    },
    async submit () {
      const task = this.validate(this.task)
      if (!task) return
      this.processing = true
      return this.addTask({ ...task, description: this.description, priority: this.priority ? 5 : 1, realtime: this.realtime })
        .then(({ status }) => {
          switch (status) {
              case 200:
                this.$notify({ group: 'general', text: this.$t('twitter.tasks.already-exists'), type: 'warn' })
                break
              case 201:
                this.$notify({ group: 'general', title: this.$t('notifications.success'), text: this.$t('whatsapp.tasks.task_added_msg'), type: 'success' })
                break
          }
          this.errors.validation = false
          this.$emit('added')
          this.task = ''
          this.description = ''
          this.priority = false
        })
        .catch(() => {
          this.errors.validation = true
        })
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>
