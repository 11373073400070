import { apiRequest } from '@/utils/api'
import config from '@/config'

class Leak {
  _limit = 500
  genericType = 'leak'
  loadDataByType (type, data) {
    return new Promise((resolve, reject) => {
      if (data) {
        apiRequest(`search/?query=${data}&query_type=${type}`, 'GET', {}, config.servers.leaks)
          .then(res => {
            res = res.data
            const result = {
              isOverLimit: res.result.length > this._limit,
              items: res.result
            }
            resolve(result)
          })
          .catch(err => {
            reject(err)
          })
      } else {
        reject(new Error(`Empty ${type}`))
      }
    })
  }
  _transformLeak (leak) {
    const loginPasswordPair = leak.line.split(':')
    let login = ''
    let password = ''
    if (loginPasswordPair && loginPasswordPair.length > 1) {
      login = loginPasswordPair.splice(0, 1)
      password = loginPasswordPair.join(':')
    } else if (loginPasswordPair.length === 1) {
      login = loginPasswordPair.splice(0, 1)
    }
    return {
      login: login[0] || '',
      password: password,
      sources: leak.sources || [],
      sourcesString: leak.sources ? leak.sources.join(', ') : ''
    }
  }
}
export default Leak
