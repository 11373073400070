<template>
  <div class="home uk-container">
    <div class="uk-margin">
      <div
        class="uk-display-inline-block uk-margin-small-bottom uk-width-expand"
        uk-grid
      >
        <form
          class="uk-grid-small uk-form-small uk-display-inline-block"
          uk-grid
          @submit.prevent="searchHandler"
        >
          <div
            v-if="type !== 'hashtag' && type !== 'retweeters'"
            class="uk-display-inline-block"
          >
            <input
              v-model="query.search"
              type="text"
              :placeholder="$t('tgcp.channels.search_placeholder')"
              class="uk-input uk-form-width-small uk-form-width-medium"
            />
            <button
              type="submit"
              class="uk-button uk-button-primary uk-search-icon"
            >
              <span uk-search-icon/>
            </button>
          </div>
          <div class="uk-display-inline-block">
            {{ $t('twitter.sort.sort_by') }}
            <vueSelect
              v-model="currentOrderBy"
              :options="orderListTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
          <div class="uk-display-inline-block">
            <vueSelect
              v-model="currentOrderType"
              :options="orderTypeTranslated"
              label="label"
              class="uk-display-inline-block uk-form-width-small uk-form-width-medium"
            />
          </div>
        </form>
      </div>
      <SearchInDbHint />
      <div
        class="uk-display-inline-block"
        style="padding-left: 50px;"
        uk-grid
      >
        <div 
          v-if="hasSearchFields" 
          class="uk-form-small uk-display-inline-block"
        >
          <label 
            v-for="field in searchList"
            :key="field + type"
          >
            <input
              v-model="query.search_fields"
              class="uk-checkbox"
              type="checkbox"
              :value="field"
            />
            {{ $t(`twitter.user.${field}`) }}
          </label>
        </div>
      </div>
      <div v-if="!loaded">
        <Loader />
      </div>
      <div v-else>
        <template v-if="loaded && !hasDataToView">
          <NotFound />
        </template>
        <Item
          v-for="user in users"
          :key="user.id"
          :user="user"
          class="uk-margin-top"
        />
        <div v-if="!hidePagination">
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
    <ExportDialog
      v-if="hasExport"
      :id="id"
      :search="query.search"
      namespace="twitter"
      type="users"
      :subtype="type"
      :query="query"
      :totalCount="pagination?.total ?? 0"
      :messages="selectedMessages"
      @deselect="selectedMessages = []"
    />
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import vueSelect from '@/components/VueSelect'
import Paginate from '@/components/Paginate'

export default {
  name: 'TwitterUserList',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    SearchInDbHint: defineAsyncComponent(() => import('@/components/general/SearchInDbHint')),
    Item: defineAsyncComponent(() => import('@/components/Twitter/UserList/Item')),
    Paginate,
    vueSelect,
    Loader
  },
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'all',
      validator: val => ['all', 'follows', 'followers', 'hashtag', 'friends', 'retweeters'].includes(val)
    }
  },
  data () {
    return {
      users: [],
      orderList: ['id'].concat(this.type !== 'follows' && this.type !== 'followers' ? ['screen_name', 'user_name', 'created_at']:[]),
      searchList: ['screen_name', 'user_name', 'description'],
      query: {
        limit: 20,
        offset: this.type === 'retweeters' ? 0 : (+this.$route.query.offset || 0),
        order_by: this.type === 'retweeters' ? 'id' : (this.$route.query.order_by || 'id'),
        order_type: this.type === 'retweeters' ? 'asc' : (this.$route.query.order_type || 'asc'),
        search: this.type === 'retweeters' ? '' : (this.$route.query.search || ''),
        search_fields: []
      },
      pagination: {
        page: 1,
        total: 0
      },
      selectedMessages: []
    }
  },
  watch: {
    id () {
      return this.update()
    }
  },
  computed: {
    ...mapGetters('twitter', ['isLoaded']),
    hasSearchFields () {
      return this.type === 'all'
    },
    hasDataToView () {
      return this.users.length > 0
    },
    hidePagination () {
      return !this.loaded || this.pagination.total < this.query.limit
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.query.limit)
      return this.pagination.total / lastPage - this.query.limit === 0 ? lastPage : lastPage + 1
    },
    loaded () {
      return this.id ? this.isLoaded['users' + this.type] : this.isLoaded['users']
    },
    currentOrderBy: {
      get () {
        return this.query.order_by
      },
      set (obj) {
        this.query.order_by = obj && obj.value
        this.searchHandler()
      }
    },
    currentOrderType: {
      get () {
        return this.query.order_type
      },
      set (obj) {
        this.query.order_type = obj && obj.value
        this.searchHandler()
      }
    },
    orderListTranslated () {
      return this.orderList.map(word => ({ label: this.$t('twitter.sort.' + word), value: word }))
    },
    orderTypeTranslated () {
      return [
        { value: 'asc', label: this.$t('twitter.sort.sort_type_asc') },
        { value: 'desc', label: this.$t('twitter.sort.sort_type_desc') }
      ]
    },
    userOrChat () {
      const { id, type } = this
      let from = type + (id ? '_' + id : '')
      if (['follows', 'followers', 'friends'].includes(type)) {
        from = type + '_from_user_' + id
      }
      return from
    },
    hasExport () {
      if (this.type === 'retweeters') return false
      const { query: { search }, $route: { name } } = this
      if (name === 'twitter_user_list' && search) {
        return true
      }
      if (name !== 'twitter_user_list' && this.users.length) {
        return true
      }
      return false
    },
    typedId () {
      const { type, id } = this
      const typedId = String
      typedId.prototype.type = type
      if (id) return typedId(id)
      return ''
    }
  },
  methods: {
    ...mapActions('twitter', ['fetchAllUsers', 'addParamToQuery', 'fetchFollow', 'fetchUsersByHashtag', 'fetchRetweeters']),
    init () {
      const page = Math.floor(this.query.offset / (this.query.limit - 1) + 1)
      this.setPage(page)

      const searchFields = this.$route.query.search_fields
      if (searchFields) this.query.search_fields = searchFields && Array.isArray(searchFields) ? searchFields : [searchFields]
    },
    setPage (page) {
      this.query.offset = this.query.limit * (page - 1)
      this.pagination.page = page
      this.update()
    },
    setData (users) {
      this.users = users.users
      this.pagination.total = users.meta.results_count
    },
    searchHandler () {
      if (!this.hasSearchFields) {
        const {
          search_fields,
          ...withoutSearchFields
        } = this.query
        this.query = withoutSearchFields
      }
      this.setPage(1)
    },
    handleError () {
      this.users = []
      this.pagination.total = 0
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    update () {
      let { type } = this
      const data = {
        id: this.id,
        query: { ...this.query }
      }
      if (type === 'followers') {
        type = 'follower'
      }
      if (type === 'follower' || type === 'follows') {
        data.query.order_by = `${type}.${data.query.order_by}`
      }
      if (type === 'friends') {
        data.query.order_by = 'follower.' + data.query.order_by
      }
      if (type === 'all') {
        this.addParamToQuery(this.query)
      } else {
        if (type !== 'hashtag') {
          data.query.order_by = data.query.order_by ? data.query.order_by : void (0)
        }
      }
      const opts = {
        hashtag: ['fetchUsersByHashtag', data],
        all: ['fetchAllUsers', data.query],
        retweeters: ['fetchRetweeters', data]
      }
      const [method, payload] = opts[type] || ['fetchFollow', { ...data, type: this.type }]
      return this[method](payload)
        .then(users => this.setData(users))
        .catch(err => !err.__CANCEL__ && this.handleError(err))
    }
  },
  created () {
    this.init()
  }
}
</script>
