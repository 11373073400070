import router from '@/router'
import config from '@/config'
import unicode from '@/utils/unicode'
import { apiRequest, getQueryString } from '@/utils/api'
import { filteredArr, filterFields, removeEscapeCharacters, dayjsCurrentTimestamp, localizeCSVFields } from '@/utils/csvExport/fieldsParse'
import { notify } from '@kyvg/vue3-notification'
const { servers: { twitter } } = config

const sanitize = require('sanitize-filename')

function reflect (promise) {
  return promise.then(
    (v) => {
      return { status: 'fulfilled', value: v }
    },
    (error) => {
      return { status: 'rejected', reason: error }
    }
  )
}

const flatternObj = (obj) => {
  const flattened = {}
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flatternObj(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}

export default {
  namespaced: true,
  state: {
    users: {},
    messages: {},
    hashtags: {},
    tasks: {},
    stats: {},
    geoTweets: [],
    userStats: {},
    searchResults: [],
    isLoaded: {},
    csv: {
      ready: 0,
      autostart: false,
      data: {},
      format: 'csv',
      filename: ''
    },
    translatedCache: new Map(),
    cancelSources: {},
    relations: {
      'linked_from': [],
      'links_to': []
    }
  },
  getters: {
    errors: state => state.errors,
    allUsers: state => state.users,
    allHashtags: state => state.hashtags,
    userStats: state => state.userStats,
    isLoaded: (state) => state.isLoaded,
    stats: (state) => state.stats,
    allMessages: (state) => state.messages,
    allListMediaMessages: state => state.listmediamessages,
    allListTasks: (state) => state.listTasks,
    allTasks: (state) => state.tasks,
    geoTweets: (state) => state.geoTweets,
    allListAdmins: state => state.listAdmins,
    allListPastChannels: state => state.listPastChannels,
    csvReady: state => state.csv.ready,
    csvData: state => state.csv.data,
    csvType: state => state.csv.type,
    csvFrom: state => state.csv.from,
    csvName: state => state.csv.filename,
    csvAutoDownload: state => state.csv.autostart,
    csvFormat: (state) => state.csv.format,
    csvAllowedFields: (state) => state.csv.allowedFields,
    userAvatars: state => state.userAvatars,
    relations: state => state?.relations,
    linkedFrom: state => state?.relations?.['linked_from'],
    linksTo: state => state?.relations?.['links_to']
  },
  mutations: {
    setCancel (state, { prop, source }) {
      state.cancelSources[prop] = source
    },
    unsetCancel (state, { prop }) {
      delete state.cancelSources[prop]
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      state.isLoaded[payload] = true
    },
    clearGeoTweets: (state, payload) => {
      state.geoTweets = []
    },
    addGeoTweet: (state, payload) => {
      state.geoTweets.push(payload.tweet)
    },
    addUserData: (state, payload) => {
      if (!payload.id) return
      state.users[payload.id] = payload
    },
    addMessageData: (state, payload) => {
      if (!payload.id) return
      state.messages[payload.id] = payload
    },
    addUserDataChatsActivity (state, payload) {
      if (!payload.user_id) return
      state.users[payload.user_id].chats_activity = payload
    },
    fetchHashtagActivityById (state, payload) {
      if (!payload.hashtag_id) return
      if (!state.hashtags[payload.hashtag_id].activity) state.hashtags[payload.hashtag_id].activity = {}
      state.hashtags[payload.hashtag_id].activity[payload.key] = payload
    },
    addUserAvatar (state, payload) {
      if (!payload.id) return
      state.userAvatars[payload.id] = payload
    },
    fetchMessageLink (state, payload) {
      if (!payload.message_id) return
      state.messages[payload.message_id].pagination = payload
    },
    fetchMediaMessageLink (state, payload) {
      if (!payload.message_id) return
      state.mediamessages[payload.message_id].pagination = payload
    },
    addUserDataActivity (state, payload) {
      if (!payload.user_id) return
      state.users[payload.user_id]['activity'][payload.key] = payload
    },
    addUserDataStats (state, payload) {
      payload.forEach(payload => {
        if (!payload.user_id) return
        state.userStats[payload.user_id] = payload
      })
    },
    addUserReplies (state, payload) {
      if (!payload.id) return
      state.users[payload.id].replies = payload
    },
    userHavePhone (state, id) {
      state.users[id].has_phone = true
    },
    userPhone: (state, payload) => {
      if (!payload.id) return
      state.users[payload.id].phone = payload.phone
    },
    addChannelData: (state, payload) => {
      if (!payload.id) return
      state.channels[payload.id] = payload
    },
    addChannelAvatar (state, payload) {
      if (!payload.id) return
      state.channelAvatars[payload.id] = payload.file_location
    },
    addChannelDataActivity (state, payload) {
      if (!payload.id) return
      state.channels[payload.id]['activity'][payload.key] = payload
    },
    addChannelDataStats (state, payload) {
      if (!payload.id) return
      state.channels[payload.id].stats = payload
    },
    fetchAllUsers (state, users) {
      if (!users) return
      users = users.map(user => {
        user.activity = {}
        state.users[user.id] = user
      })
    },
    fetchAllTasks (state, tasks) {
      tasks.map(task => {
        if (!state.tasks[task.id]) state.tasks[task.id] = task
      })
    },
    fetchTaskChats (state, { chats, id }) {
      if (!chats) return
      state.tasks[id].chats = chats
    },
    listUsers (state, users) {
      const page = ++Object.keys(state.listUsers).length
      const usersId = users.map(user => user.id)
      state.listUsers[page] = usersId
    },
    addHiddenParamToQuery: (state, param) => Object.assign(state.queryParams, param),
    setPage: (state, page) => {
      state.pagination.currentPage = page
    },
    setPageWithType: (state, page) => {
      state.pagination[page.type] = page.page
    },
    fetchUsersToView: (state, { usersId, page }) => {
      if (usersId[state.queryParams.limit - 1] !== undefined) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listUsers[page] = usersId
    },
    fetchAdminsToView: (state, { usersId, page }) => {
      if (usersId[state.queryParams.limit - 1] !== undefined) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listAdmins[page] = usersId
    },
    fetchMessagesToView: (state, { messageId, page }) => {
      if (messageId[state.queryParams.limit - 1] !== undefined) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listMessages[page] = messageId
    },
    fetchmediamessagesToView: (state, { messageId, page }) => {
      if (messageId[state.queryParams.limit - 1] !== undefined) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listmediamessages[page] = messageId
    },
    deleteTask: (state, taskId) => {
      delete state.tasks[taskId]
    },
    fetchTasksToView: (state, { taskId, page }) => {
      if (taskId[state.queryParams.limit - 1] !== undefined) state.pagination.nextPage = true
      else state.pagination.nextPage = false
      state.listTasks[page] = taskId
    },
    clearUsersToView: (state) => {
      delete state.listUsers
      state.listUsers = {}
    },
    clearUsers: (state) => {
      delete state.users
      state.users = {}
    },
    clearMessagesToView: (state) => {
      delete state.listMessages
      state.listMessages = {}
    },
    clearMediaMessagesToView: state => {
      delete state.listmediamessages
      state.listmediamessages = {}
    },
    clearCSVData: state => {
      delete state.csv.data
      state.csv.data = {}
    },
    fetchAllMessages: (state, messages) => {
      messages.map(message => {
        if (message.photos) {
          for (var i in message.photos) {
            if (message.photos[i].file_location) {
              message.photos[i].file_location = config.servers.tgcp + message.photos[i].file_location
            }
          }
        }
        if (message.documents) {
          for (var j in message.documents) {
            if (message.documents[j].file_location) {
              message.documents[j].file_location = config.servers.tgcp + message.documents[j].file_location
            }
          }
        }
        if (!state.messages[message.id]) state.messages[message.id] = message
      })
    },
    clearMessages: (state) => {
      delete state.messages
      state.messages = {}
    },
    updateStats: (state, stats) => {
      state.stats = stats
    },
    csvReady: (state, percent) => {
      state.csv.ready = percent
    },
    setCSVname: (state, name) => {
      state.csv.filename = name
    },
    downloadcsv: (state, { data, type, from }) => {
      if (type) state.csv.type = type
      if (from) state.csv.from = from
      state.csv.data = data
    },
    setAutoDownload: (state, autostart) => {
      state.csv.autostart = autostart
    },
    fetchAllHashtags: (state, hashtags) => {
      hashtags.map(hashtag => {
        if (!state.hashtags[hashtag.id])
          state.hashtags[hashtag.id] = hashtag
      })
    },
    fetchHashtag: (state, hashtag) => {
      state.hashtags[hashtag.id] = hashtag
    },
    clearAllowedFields: (state) => {
      delete state.csv.allowedFields
      state.csv.allowedFields = {}
    },
    setRelations: (state, relations) => {
      try {
        if (Array.isArray(relations?.['links_to'])) {
          state.relations['links_to'] = relations['links_to']
        }
        if (Array.isArray(relations?.['linked_from'])) {
          state.relations['linked_from'] = relations['linked_from']
        }
      } catch (error) {
        console.debug(error)
      }
    }
  },
  actions: {
    fetchRelations: async ({ commit, dispatch }, params) => {
      try {
        const id = params?.id
        const response = await dispatch('fetcher/fetchRelations', `twitter://user/${id}`, {
          root: true
        })
        const linksTo = response?.data?.['links_to']
        const linkedFrom = response?.data?.['linked_from']
        commit('setRelations', {
          'links_to': linksTo,
          'linked_from': linkedFrom
        })
      } catch (error) {
        console.debug(error)
      }
    },
    fetchUserRelations ({ dispatch }, id) {
      dispatch('fetchRelations', { id })
    },
    async fetchUserByName (...args) {
      const name = args?.[1]
      const hasName = (
        typeof name === 'string' &&
        name?.length > 0
      )
      if (!hasName) {
        return Error()
      }
      const params = new URLSearchParams()
      const SEARCH_FIELDS = 'search_fields'
      const USER_NAME = 'user_name'
      const SEARCH = 'search'
      params.set(
        SEARCH_FIELDS,
        USER_NAME
      )
      params.set(
        SEARCH,
        name
      )
      const search = params.toString()
      const response = await apiRequest(`/users?${search}`, 'GET', {}, twitter)
      const user = response?.data?.data?.[0]
      if (
        typeof user === 'object' &&
        name === user?.[USER_NAME]
      ) {
          return user
      } else {
        const PATH = '/resolver/user/username/'.concat(name)
        const resolver = await apiRequest(PATH, 'GET', {}, twitter)
        if (
          typeof resolver?.data === 'object' &&
          resolver?.data !== null
        ) {
          return resolver.data
        }
      }
      return Promise.reject(Error())
    },
    async fetchUserHistory ({ commit, dispatch }, id) {
      return (await apiRequest(`/users/${id}/history`, 'GET', {}, twitter)).data.data
    },
    checkError ({ commit, dispatch }, err) {
      if (err.response && err.response.status === 401) {
        if (err.response.data.code === 'TOKEN_EXPIRED') {
          dispatch('refresh', null, { root: true }).then(() => {
            if (err.response.callAgain) {
              // dispatch(err.response.callAgain.name, err.response.callAgain.argument, { root: true })
            }
          })
        }
      }
    },
    clearGeo ({ commit, dispatch, rootState }) {
      commit('clearGeoTweets')
    },
    geoBoost ({ commit, dispatch }, coordinates) {
      let bbox = coordinates[1] + ',' + coordinates[0] + ',' + coordinates[3] + ',' + coordinates[2]
      return new Promise((resolve, reject) => {
        apiRequest(`/tweets/geo/recent?bbox=${encodeURIComponent(bbox)}`, 'GET', {}, twitter)
          .then((data) => {
            if (data.status) {
              data = data.data.data
              for (let i in data) {
                if (data[i].raw) commit('addGeoTweet', { tweet: data[i].raw })
                else commit('addGeoTweet', { tweet: data[i] })
              }
            }
            resolve(data)
          }).catch(err => {
            reject(err)
          }).finally(() => {
          })
      })
    },
    addGeoTweet ({ commit, dispatch, rootState }, tweet) {
      commit('addGeoTweet', tweet)
    },
    deleteTask ({ commit, dispatch, rootState }, taskId) {
      return apiRequest(`/tasks/` + taskId, 'DELETE', {}, twitter).then(() => {
        commit('deleteTask', taskId)
      })
    },
    async addTask ({ commit }, task) {
      try {
        return await apiRequest(`/tasks`, 'POST', {}, twitter, task)
      } catch (err) {
        if (err.response) {
          switch (err.response && err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        throw err
      }
    },
    async editTask ({ commit }, { id, task }) {
      try {
        const { status, data } = await apiRequest(`/tasks/${id}`, 'PUT', {}, twitter, task)
        switch (status) {
          case 200:
            notify({ group: 'general', text: 'Updated', type: 'success' })
            break
        }
        return data
      } catch (err) {
        if (err.response) {
          switch (err.response && err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        throw err
      }
    },
    async massTaskAdd ({ commit, dispatch, rootState }, { tasks, needAddToCase }) {
      try {
        const { status, data } = await apiRequest(`/tasks/batch`, 'POST', {}, twitter, tasks)
        switch (status) {
          case 200:
            notify({ group: 'general', text: 'Created', type: 'success' })
            break
        }
        return data
      } catch (err) {
        if (err.response) {
          switch (err.response && err.response.status) {
            case 400:
              notify({ group: 'general', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'general', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        throw err
      }
    },
    fetchStats ({ commit, state, dispatch, rootState }, id) {
      if (!state.stats.hasOwnProperty('tweets_count')) {
        commit('startLoad', `stats`)
      }
      apiRequest(`/stats`, 'GET', {}, twitter)
        .then((data) => {
          commit('updateStats', data.data)
        }).catch(err => {
          err.response.callAgain = { name: 'twitter/fetchStats', argument: null }
          dispatch('checkError', err)
          if (err.response && err.response.status === 404) console.error(err)
        }).finally(() => {
          commit('stopLoad', `stats`)
        })
    },
    fetchUserAvatarsById ({ commit, state, rootState, dispatch }, id) {
      if (state.userAvatars[id]) {
        commit('stopLoad', `user_${id}_avatars`)
        return
      }
      commit('startLoad', `user_${id}_avatars`)
      apiRequest(`/users/${id}/all_avatars`, 'GET')
        .then((data) => {
          data.data.id = id
          data.data.forEach((e, i, data) => {
            data[i].file_location = config.servers.tgcp + e.file_location
          })
          commit('addUserAvatar', data.data)
        }).catch(() => {
          commit('addUserAvatar', { error: true, id: id })
        }).finally(() => {
          commit('stopLoad', `user_${id}_avatars`)
        })
    },
    fetchMessageLink ({ commit, state, rootState }, { messageId, chatId }) {
      return new Promise((resolve, reject) => {
        apiRequest(`/messages/${chatId}/${messageId}/offset`, 'GET')
          .then((data) => {
            let offset = data.data
            const limit = state.defaultParams.channellist_limit
            const page = Math.floor(offset / (limit - 1) + 1)
            offset = ((limit - 1) * page) - (limit - 1)
            resolve({ id: chatId, message_id: messageId, message_pagination: { page, offset }, tab: 'messages' })
          }).catch((err) => {
            reject(err)
          })
      })
    },
    fetchMediaMessageLink ({ commit, state, rootState }, { messageId, chatId }) {
      if (state.mediamessages[messageId] && state.mediamessages[messageId].pagination) return
      commit('startLoad', `mediamessage_${messageId}_link`)
      apiRequest(`/v1/messages/${chatId}/${messageId}/offset`, 'GET')
        .then((data) => {
          data.data.message_id = messageId
          data.data.chat_id = chatId
          let offset = data.data
          const limit = state.defaultParams.channellist_limit
          const page = Math.floor(offset / (limit - 1) + 1)
          offset = ((limit - 1) * page) - (limit - 1)
          data.data.page = page
          data.data.offset = offset
          commit('fetchMediaMessageLink', data.data)
        }).catch(() => {
        }).finally(() => {
          commit('stopLoad', `mediamessage_${messageId}_link`)
        })
    },
    fetchFriends ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', `user_${id}_friends`)
      return new Promise((resolve, reject) => {
        apiRequest(`/users/${id}/friends`, 'GET', {}, twitter)
          .then((data) => {
            var userData = data.data.data
            userData.user_id = id
            commit('fetchAllUsers', userData)
            resolve(userData)
          }).catch((err) => {
            if (err.response && err.response.status === 404) {
            }
          }).finally(() => {
            commit('stopLoad', `user_${id}_friends`)
          })
      })
    },
    fetchUserChatsActivityById  ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', `user_${id}_ca`)
      apiRequest(`/users/${id}/top/hash`, 'GET', {}, twitter)
        .then((data) => {
          var userData = data.data
          userData.user_id = id
          commit('addUserDataChatsActivity', userData)
        }).catch((err) => {
          if (err.response && err.response.status === 404) {
          }
        }).finally(() => {
          commit('stopLoad', `user_${id}_ca`)
        })
    },
    fetchUserActivityById  ({ commit, state, dispatch, rootState }, obj) {
      var id = obj[0]
      var type = obj[1]
      commit('startLoad', `user_${id}_activity_${type}`)
      apiRequest(`/users/${id}/activity/${type}`, 'GET', {}, twitter)
        .then((data) => {
          var userData = data.data
          userData.user_id = id
          userData.key = type
          commit('addUserDataActivity', userData)
        }).catch((err) => {
          if (err.response && err.response.status === 404) {
          }
        }).finally(() => {
          commit('stopLoad', `user_${id}_activity_${type}`)
        })
    },
    async fetchHashtagActivityById ({ commit, dispatch, state }, { id, type }) {
      commit('startLoad', `hashtag_${id}_activity_${type}`)
      if (!state.hashtags[id]) {
        try {
          await dispatch('fetchHashtagById', id)
        } catch (err) {
          throw new Error(`Can't fetch tag`)
        }
      }
      try {
        var res = await apiRequest(`/hashtags/${id}/activity/${type}`, 'GET', {}, twitter)
        var tagData = res.data
        tagData.hashtag_id = id
        tagData.key = type
      } catch (err) {
        throw err
      } finally {
        commit('stopLoad', `hashtag_${id}_activity_${type}`)
      }
      commit('fetchHashtagActivityById', tagData)
      return res.data.activity
    },
    fetchUserPhoneAvailabilityById  ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', `user_${id}_has_phone`)
      apiRequest(`/v1/resolver/user/${id}/exists`, 'GET')
        .then((data) => {
          commit('userHavePhone', id)
        }).catch(() => {
        }).finally(() => {
          commit('stopLoad', `user_${id}_has_phone`)
        })
    },
    fetchUserPhoneById  ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', `user_${id}_phone`)
      return apiRequest(`/v1/resolver/user/${id}/phone`, 'GET')
        .then((data) => {
          commit('userPhone', { id: id, phone: data.data.phone })
        }).catch(() => {
        }).finally(() => {
          commit('stopLoad', `user_${id}_phone`)
        })
    },
    fetchUserById ({ commit, state, dispatch, rootState }, id) {
      if (state.users[id]) {
        commit('stopLoad', 'user_' + id)
        return
      }
      dispatch('fetchForceUserById', id)
    },
    fetchForceUserById ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', 'user_' + id)
      commit('startLoad', 'user_' + id + '_stats')
      return new Promise((resolve, reject) => {
        apiRequest('/users/' + id, 'GET', {}, twitter)
          .then((data) => {
            var userData = data.data
            userData.activity = {}
            userData.answer_status = 'FOUND'
            commit('addUserData', userData)
            resolve(userData)
          }).catch((err) => {
          if (err.response && err.response.status === 404) {
            setTimeout(function () {
              dispatch('fetchUserById', id)
            }, 5000)
          }
          reject(err)
        }).finally(() => {
          commit('stopLoad', 'user_' + id)
        })
      })
    },
    async fetchAllHashtags ({ commit, state, dispatch }, query) {
      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = getQueryString(query)
      let cancelled = false
      const prop = 'hashtags'
      commit('startLoad', prop)
      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const prop = 'users_hashtag'
        const cancellation = getCancellation({ prop, commit, state })
        const { data } = await apiRequest('/hashtags?' + queryString, 'GET', {}, twitter, undefined, cancellation)
        commit('fetchAllHashtags', data.data)
        return data
      } catch (err) {
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        !cancelled && commit('stopLoad', prop)
      }
    },
    async fetchUsersByHashtag ({ commit, state }, { id, query, isExport }) {
      const queryString = getQueryString(query)
      let cancelled = false
      const prop = 'usershashtag'
      if (!isExport) commit('startLoad', prop)
      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data: users, meta } } = await apiRequest(`/hashtags/${id}/users?` + queryString, 'GET', {}, twitter, undefined, cancellation)
        if (!isExport) commit('fetchAllUsers', users)
        return { users, meta }
      } catch (err) {
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled && !isExport) && commit('stopLoad', prop)
      }
    },
    async fetchTogetherHashtags ({ commit, state, dispatch }, { id, query }) {
      const queryString = getQueryString(query)
      let cancelled = false
      const prop = 'hashtags'
      commit('startLoad', prop)
      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const cancellation = getCancellation({ prop, commit, state })
        const { data } = await apiRequest(`/hashtags/${id}/usedtogether?${queryString}`, 'GET', {}, twitter, undefined, cancellation)
        const hashtags = data.data
        commit('fetchAllHashtags', hashtags)
        return data
      } catch (err) {
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        !cancelled && commit('stopLoad', prop)
      }
    },
    fetchHashtagById ({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        commit('startLoad', 'hashtag_' + id)
        if (state.hashtags[id] && state.hashtags[id].users_count !== undefined) {
          commit('stopLoad', 'hashtag_' + id)
          return resolve(state.hashtags[id])
        } else {
          apiRequest('/hashtags/' + id, 'GET', {}, twitter)
            .then(res => {
              var hashtag = res.data
              commit('fetchHashtag', hashtag)
              resolve(hashtag)
            })
            .catch(err => reject(err))
            .finally(() => commit('stopLoad', 'hashtag_' + id))
        }
      })
    },
    fetchMessageById ({ commit, state, dispatch, rootState }, replyData) {
      const id = replyData.message_id
      const chatId = replyData.chat_id
      if (state.messages[id]) {
        commit('stopLoad', `message_` + id)
        return
      }
      commit('startLoad', 'message_' + id)
      apiRequest('/v1/messages/' + chatId + '/' + id, 'GET')
        .then((data) => {
          var userData = data.data
          userData.id = id
          commit('addMessageData', userData)
        }).finally(() => {
          commit('stopLoad', `message_` + id)
        })
    },
    fetchTaskById (_, id) {
      return apiRequest(`/tasks/${id}`, 'GET', {}, twitter)
    },
    fetchMessageReposts ({ state, dispatch, rootState }, { chatId, messageId }) {
      apiRequest(`/v1/messages/${chatId}/${messageId}/repost`, 'GET')
    },
    fetchUserStatsById ({ commit, state, dispatch, rootState }, id) {
      if (state.users[id] && state.users[id].stats) return
      dispatch('fetchForceUserStatsById', id)
    },
    fetchForceUserStatsById ({ commit, state, dispatch, rootState }, id) {
      commit('startLoad', 'user_' + id + '_stats')
      apiRequest(`/users/${id}/stats?last_only=true`, 'GET', {}, twitter)
        .then((data) => {
          var userData = data.data
          if (userData) commit('addUserDataStats', userData)
        }).finally(() => {
        commit('stopLoad', `user_${id}_stats`)
      })
    },
    getFromCacheOrRequestUserChannelList ({ state, dispatch, rootState }, { id, access }) {
      return apiRequest(`/v1/users/${id}/${access}/all`, 'GET')
    },
    getFromCacheOrRequestUserData ({ state, dispatch, rootState }, id) {
      if (state.users[id]) {
        return Promise.resolve({ data: state.users[id] })
      } else {
        return new Promise((resolve, reject) => {
          apiRequest(`/users/${id}`, 'GET', {}, twitter).then(function (data) {
            return resolve(data)
          })
        })
      }
    },
    getFromCacheOrRequestUserAvatar ({ state, dispatch, rootState }, id) {
      if (state.users['id'] && state.userAvatars[id]) {
        return Promise.resolve({ data: { file_location: state.userAvatars[id] } })
      } else {
        return apiRequest(`/v1/users/${id}/avatar`, 'GET')
      }
    },
    getUserReplies ({ state, dispatch, rootState }, id) {
      return apiRequest(`/v1/users/${id}/replies/`, 'GET')
        .then((data) => {
          var userData = data.data
          userData.id = id
          return Promise.resolve(userData)
        })
    },
    getFullUserData ({ state, dispatch, rootState }, id) {
      return Promise.all([
        dispatch('getFromCacheOrRequestUserData', id),
        dispatch('getFromCacheOrRequestUserAvatar', id)
      ].map(reflect)).then(function (values) {
        var user = {}
        for (var i in values) {
          if (values[i].status === 'fulfilled') {
            var data = values[i].value.data
            if (data.hasOwnProperty('file_location')) {
              user.avatar_src = config.servers.tgcp + data.file_location
            } else {
              user = { ...data, ...user }
            }
          }
        }
        return Promise.resolve(user)
      })
    },
    async fetchUserRepliesById ({ commit, state, dispatch, rootState }, id) {
      if (!id) return
      commit('startLoad', `user_${id}_replies`)
      if (!state.users[id]) {
        await dispatch('fetchUserById', id)
      }
      apiRequest(`/v1/users/${id}/replies/`, 'GET', {}, twitter)
        .then(({ data }) => commit('addUserReplies', { ...data, id }))
        .finally(() => commit('stopLoad', `user_${id}_replies`))
    },
    async fetchUsersToView ({ commit, dispatch, state }, users) {
      return new Promise((resolve, reject) => {
        let offsetPassed = router.currentRoute.value.query?.offset ?? 0
        let page = 0
        if (offsetPassed) {
          page = Math.floor(offsetPassed / (state.queryParams.limit - 1) + 1)
        } else page = ++Object.keys(state.listUsers).length
        if (Number(offsetPassed) === 0) page = 1
        const usersId = users.map(user => user.id)
        commit('fetchUsersToView', { usersId, page })
        resolve()
      })
    },
    async fetchAdminsToView ({ commit, dispatch, state }, users) {
      return new Promise((resolve, reject) => {
        let offsetPassed = router.currentRoute.value.query?.offset ?? 0
        let page = 0
        if (offsetPassed) {
          page = Math.floor(offsetPassed / (state.queryParams.limit - 1) + 1)
        } else page = ++Object.keys(state.listAdmins).length
        if (Number(offsetPassed) === 0) page = 1
        const usersId = users.map(user => user.id)
        commit('fetchAdminsToView', { usersId, page })
        resolve()
      })
    },
    fetchMessagesToView ({ dispatch, commit, state }, messages) {
      return new Promise((resolve, reject) => {
        if (!messages[0]) reject(commit('fetchMessagesToView', { messageId: 0, page: 1 }))
        let offsetPassed = router.currentRoute.value.query?.offset || (messages[0] ? messages[0].offset : 0)
        let page = 0
        if (offsetPassed) {
          page = Math.floor(offsetPassed / (messages[0].limit - 1) + 1)
        } else page = ++Object.keys(state.listMessages).length
        if (Number(offsetPassed) === 0) page = 1
        const messageId = messages.map(message => message.message_id)
        commit('fetchMessagesToView', { messageId, page })
        resolve()
      })
    },
    fetchmediamessagesToView ({ dispatch, commit, state }, messages) {
      return new Promise((resolve, reject) => {
        let offsetPassed = router.currentRoute.value.query?.offset ?? 0
        let page = 0
        if (offsetPassed) {
          page = Math.floor(offsetPassed / (state.queryParams.limit - 1) + 1)
        } else page = ++Object.keys(state.listmediamessages).length
        if (Number(offsetPassed) === 0) page = 1
        const messageId = messages.map(message => message.message_id)
        commit('fetchmediamessagesToView', { messageId, page })
        resolve()
      })
    },
    fetchTasksToView ({ dispatch, commit, state }, tasks) {
      return new Promise((resolve, reject) => {
        let offsetPassed = router.currentRoute.value.query?.offset ?? 0
        let page = 0
        if (offsetPassed) {
          page = Math.floor(offsetPassed / (state.queryParams.limit - 1) + 1)
        } else page = ++Object.keys(state.listTasks).length
        if (Number(offsetPassed) === 0) page = 1
        const taskId = tasks.map(task => task.id)
        commit('fetchTasksToView', { taskId, page })
        resolve()
      })
    },
    async fetchAllUsers ({ dispatch, commit, state, rootState }, query) {
      const { isExport } = query
      if (isExport) delete query.isExport

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = query ? '?' + getQueryString(query) : ''
      let cancelled = false
      const prop = 'users'
      if (!isExport) commit('startLoad', prop)
      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data: users, meta } } = await apiRequest(`/users/${queryString}`, 'GET', {}, twitter, undefined, cancellation)
        if (!isExport) commit('fetchAllUsers', users)
        return { users, meta }
      } catch (err) {
        console.debug(err)
        if (err.response && err.response.status === 404) console.error(err)
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled && !isExport) && commit('stopLoad', prop)
      }
    },
    async fetchAllTasks ({ commit }, query) {
      try {
        const queryString = getQueryString(query)
        const { data } = await apiRequest(`/tasks/?${queryString}`, 'GET', {}, twitter)
        commit('fetchAllTasks', data.data)
        return data
      } catch (err) {
        if (err.response) {
          switch (err.response && err.response.status) {
            case 400:
              notify({ group: 'twitter_tasks', title: 'Parameter validation fails', type: 'error' })
              break
            default:
              notify({ group: 'twitter_tasks', title: 'Service unavailable', type: 'error' })
              break
          }
        }
        throw err
      }
    },
    fetchTaskChats ({ dispatch, commit, state, rootState }, id) {
      commit('startLoad', 'taskChats_' + id)
      apiRequest(`/v1/tasks/${id}/chats`, 'GET')
        .then(data => {
          const chatTasks = data.data
          commit('fetchTaskChats', { chats: chatTasks, id: id })
        }).finally(() => {
          commit('stopLoad', 'taskChats_' + id)
        })
    },
    fetchFollow ({ dispatch, commit, state }, params) {
      const { isExport } = params
      return new Promise((resolve, reject) => {
        const id = params.id || reject(new Error('id required'))
        const type = params.type || reject(new Error('type required'))
        const query = getQueryString(params.query)
        if (!isExport) commit('startLoad', 'users' + type)
        apiRequest(`/users/${id}/${type}/?${query}`, 'GET', {}, twitter)
          .then(res => {
            var users = res.data.data.map(el => el.follower || el.follows)
            var meta = res.data.meta
            resolve({ users, meta })
            if (!isExport) commit('fetchAllUsers', users)
          })
          .catch(err => console.debug(err))
          .finally(() => {
            if (!isExport) {
              return commit('stopLoad', 'users' + type)
            }
          })
      })
    },
    async fetchRetweeters ({ dispatch, commit, state }, params) {
      commit('startLoad', `usersretweeters`)
      const id = params.id
      const query = getQueryString(params.query)
      try {
        const { data } = await apiRequest(`/tweets/${id}/retweeters?${query}`, 'GET', {}, twitter)
        const result = {
          users: [...data.data],
          meta: {...data.meta}
        }
        commit('stopLoad', `usersretweeters`)
        return result
      } catch (err) {
        console.debug(err)
      } finally {
        commit('stopLoad', `usersretweeters`)
      }
    },
    fetchChannelById ({ commit, state, dispatch, rootState }, id) {
      if (state.channels[id]) {
        commit('stopLoad', 'channel_' + id)
        return
      }
      commit('startLoad', 'channel_' + id)
      commit('startLoad', 'channel_' + id + '_stats')
      apiRequest('/v1/chats/' + id, 'GET')
        .then((data) => {
          var userData = data.data
          userData.activity = {}
          userData.avatar_src = ''
          userData.answer_status = 'FOUND'
          commit('addChannelData', userData)
        }).catch((err) => {
          if (err.response && err.response.status === 404) {
            commit('addChannelData', { id: id, 'answer_status': 'NOT_FOUND' })
          }
        }).finally(() => {
          commit('stopLoad', 'channel_' + id)
        })
    },
    fetchChannelStatsById ({ commit, state, dispatch, rootState }, id) {
      if (state.channels[id] && state.channels[id].stats) {
        commit('stopLoad', `channel_${id}_stats`)
        return
      }
      commit('startLoad', 'channel_' + id + '_stats')
      apiRequest(`/v1/chats/${id}/stats`, 'GET')
        .then((data) => {
          var userData = data.data
          userData.id = id
          commit('addChannelDataStats', userData)
        }).catch((err) => {
          err.response.callAgain = { name: 'tgcp/fetchChannelStatsById', argument: id }
          dispatch('checkError', err)
        }).finally(() => {
          commit('stopLoad', `channel_${id}_stats`)
        })
    },
    async addParamToQuery ({ commit }, param) {
      const oldQuery = router.currentRoute.value.query
      const newQuery = { ...oldQuery, ...param }
      Object.keys(newQuery).forEach((k) => !newQuery[k] && delete newQuery[k])
      await router.replace({ query: newQuery})
    },
    addHiddenParamToQuery ({ commit }, param) {
      commit('addHiddenParamToQuery', param)
    },
    setPage ({ commit }, page) {
      commit('setPage', page)
    },
    setPageWithType ({ commit }, page) {
      commit('setPageWithType', page)
    },
    clearUsersToView ({ commit }) {
      commit('clearUsersToView')
    },
    clearMessagesToView ({ commit }) {
      commit('clearMessagesToView')
    },
    async fetchHashtagMessages ({ commit, state }, { id, query, isExport }) {
      if (!id) throw new Error('id required')
      const queryString = getQueryString(query)
      let cancelled = false
      const prop = 'messages_hashtag_' + id
      if (!isExport) commit('startLoad', prop)
      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data: messages, meta } } = await apiRequest(`/hashtags/${id}/tweets?${queryString}`, 'GET', {}, twitter, undefined, cancellation)
        if (!isExport) commit('fetchAllMessages', messages)
        return { messages, meta }
      } catch (err) {
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled && !isExport) && commit('stopLoad', prop)
      }
    },
    async fetchUserMessages ({ commit, state }, params) {
      const { isExport } = params
      const id = params.id
      if (!id) throw new Error('id required')
      const queryString = getQueryString(params.query)
      let cancelled = false
      const prop = 'messages_user_' + id
      if (!isExport) commit('startLoad', prop)
      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data: messages, meta } } = await apiRequest(`/users/${id}/tweets/?${queryString}`, 'GET', {}, twitter, undefined, cancellation)
        if (!isExport) commit('fetchAllMessages', messages)
        return { messages, meta }
      } catch (err) {
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled && !isExport) && commit('stopLoad', prop)
      }
    },
    async fetchAllMessages ({ dispatch, commit, state, rootState }, query) {
      const { isExport } = query
      if (isExport) delete query.isExport

      if (query.search) {
        query.search = unicode.normalize(query.search)
      }

      const queryString = getQueryString(query)
      let cancelled = false
      const prop = 'messages'
      if (!isExport) commit('startLoad', prop)
      try {
        const getCancellation = (await import(/* webpackChunkName: "getCancellation" */ '@/utils/getCancellation')).default
        const cancellation = getCancellation({ prop, commit, state })
        const { data: { data: messages, meta } } = await apiRequest(`/tweets/?${queryString}`, 'GET', {}, twitter, undefined, cancellation)
        if (!isExport) commit('fetchAllMessages', messages)
        return { messages, meta }
      } catch (err) {
        console.error(err)
        if (err.response) {
          err.response.callAgain = {
            name: 'tgcp/fetchAllMessages',
            argument: null
          }
          dispatch('checkError', err)
          if (err.response && err.response.status === 404) console.error(err)
        }
        if (err.__CANCEL__) cancelled = true
        throw err
      } finally {
        (!cancelled && !isExport) && commit('stopLoad', prop)
      }
    },
    fetchAllmediamessages ({ dispatch, commit, state, rootState }, query) {
      commit('startLoad', 'message')
      if (!query) query = Object.assign({}, state.queryParams, router.currentRoute.value.query)
      const queryString = getQueryString(query)
      apiRequest(`/v1/messages/?${queryString}`, 'GET')
        .then(data => {
          var messages = data.data
          dispatch('fetchmediamessagesToView', messages)
            .then(() => {
              commit('fetchAllMessages', messages)
            }).finally(() => {
              commit('stopLoad', `message`)
            })
        })
        .catch(err => {
          console.debug(err)
          err.response.callAgain = { name: 'tgcp/fetchAllMessages', argument: null }
          dispatch('checkError', err)
          if (err.response.status === 404) console.error(err)
        })
    },
    async downloadcsvChatUsers ({ dispatch, commit, state, rootState }, { id, query, type, allowedFields, from, localizeFields, name }) {
      if (state.csv.ready > 0 && state.csv.ready < 100) return
      dispatch('clearCSVData')
      commit('csvReady', 40)
      commit('setCSVname', name)
      let fullData = []
      const final = rootState.settings.limits['asiris.export_chat_users_limit']
      const limit = 100
      let users = []
      let localQuery = { ...query }
      if (id && id.type === 'followers') {
        localQuery.order_by = `follower.${query.order_by}`
      }
      if (id && id.type === 'follows') {
        localQuery.order_by = `${id.type}.${query.order_by}`
      }
      if (id && id.type === 'friends') {
        localQuery.order_by = 'follower.' + query.order_by
      }
      for (let i = 0; i < final; i += limit) {
        localQuery.offset = i
        localQuery.limit = limit
        try {
          if (id && id.type === 'follows' || id.type === 'followers' || id.type === 'friends') {
            users = (await dispatch('fetchFollow', { id, type: id.type, query: localQuery, isExport: true })).users
          } else if (id) {
            users = (await dispatch('fetchUsersByHashtag', { id, query: localQuery, isExport: true })).users
          } else if (!id) {
            users = (await dispatch('fetchAllUsers', { ...localQuery, isExport: true })).users
          }
          if (users.length === 0) break
          for (let j = 0, len = users.length; j < len; j++) {
            let user = flatternObj(users[j])
            fullData.push(user)
          }
        } catch (err) {
          console.debug(err)
          continue
        }
      }

      fullData = fullData.map((prevObj) => {
		let obj = Object.fromEntries(Object.entries(prevObj))
        obj.cdate = dayjsCurrentTimestamp({ date: obj.cdate, timezone: rootState.settings.timezone })
        obj.created_at = dayjsCurrentTimestamp({ date: obj.created_at, timezone: rootState.settings.timezone })
        obj.sync_date = dayjsCurrentTimestamp({ date: obj.sync_date, timezone: rootState.settings.timezone })
        obj = removeEscapeCharacters(obj)

        if (localizeFields) obj = localizeCSVFields(obj, 'hashtagUsers')
        if (allowedFields) obj = filterFields(obj, allowedFields)
        return obj
      })
      commit('csvReady', 100)
      commit('downloadcsv', { data: fullData, type, from })
    },
    translateText: async ({ state, rootState }, { text }) => {
      const translateTo = rootState.settings.translate
      try {
        const translatedCacheKey = JSON.stringify({
          translateTo,
          text
        })
        const hasTranslatedCache = state.translatedCache.has(translatedCacheKey)
        if (!hasTranslatedCache) {
          const {
            data: { translation }
          } = await apiRequest(`/translate`, 'POST', {}, config.servers.lang_server, {
            lang: translateTo,
            text: text
          })
          state.translatedCache.set(translatedCacheKey, { data: { translation } })
          return {
            data: {
              translation
            }
          }
        }
        return state.translatedCache.get(translatedCacheKey)
      } catch (error) {
        console.debug('[Translate/catch]', error)
      }
    },
    async downloadcsvMessages ({ dispatch, commit, state, rootState }, { id, query, messageLimit, allowedFields, from, format, type, localizeFields, messages, translateMessages, name }) {
      if (state.csv.ready > 0 && state.csv.ready < 100) return
      dispatch('clearCSVData')
      commit('csvReady', 40)
      commit('setCSVname', name)
      let fullData = []
      let localQuery = {
        ...query,
        offset: 0,
        limit: 100
      }
      let final = 10000
      while (fullData.length < final) {
        try {
          let fetchedMessages
          if (id && id.type === 'user') {
            fetchedMessages = (await dispatch('fetchUserMessages', { id, query: localQuery, isExport: true })).messages
          } else if (id) {
            fetchedMessages = (await dispatch('fetchHashtagMessages', { id, query: localQuery, isExport: true })).messages
          } else {
            fetchedMessages = (await dispatch('fetchAllMessages', { ...localQuery, isExport: true })).messages
          }

          let { data } = messages ? { data: messages } : { data: fetchedMessages }

          for (let j = 0, len = data.length; j < len; j++) {
            if (+messageLimit === 0) {
              fullData.push(data[j])
            }
            fullData.push(flatternObj(data))
          }
          localQuery.offset += localQuery.limit
          if (!data.length || data.length === 1) break
        } catch (err) {
          console.debug(err)
          break
        }
      }
      try {
        if (translateMessages) {
          fullData = await Promise.all(
            fullData.map(async (entry) => {
              const {
                data: { translation }
              } = await dispatch('translateText', { text: entry.tweet_text })
              entry.translated_tweet = translation
              return entry
            })
          )
        }
      } catch (err) {
        console.debug(err)
      }

      fullData = filteredArr(fullData, 'id')
      fullData = fullData.map((prevObj) => {
		let obj = Object.fromEntries(Object.entries(prevObj))
        obj.cdate = dayjsCurrentTimestamp({ date: obj.cdate, timezone: rootState.settings.timezone })
        obj.created_at = dayjsCurrentTimestamp({ date: obj.created_at, timezone: rootState.settings.timezone })
        obj.sync_date = dayjsCurrentTimestamp({ date: obj.sync_date, timezone: rootState.settings.timezone })
        obj = removeEscapeCharacters(obj)
        if (localizeFields) obj = localizeCSVFields(obj, type)
        if (allowedFields) obj = filterFields(obj, allowedFields)
        return obj
      })
      commit('csvReady', 100)
      commit('downloadcsv', { data: fullData, type, from, allowedFields, format })
      commit('clearAllowedFields')
    },
    updateFileName: async ({ commit }, { type, from }) => {
      let name = sanitize(`${type}_${from}`)
      commit('setCSVname', name)
    },
    clearMessages ({ commit }) {
      commit('clearMessages')
    },
    clearUsers ({ commit }) {
      commit('clearUsers')
    },
    clearMediaMessagesToView ({ commit }) {
      commit('clearMediaMessagesToView')
    },
    clearCSVData ({ commit }) {
      commit('clearCSVData')
    },
    setcsvReady ({ commit }, percent) {
      commit('csvReady', percent)
    },
    setAutoDownload ({ commit }, autostart) {
      commit('setAutoDownload', autostart)
    }
  }
}
