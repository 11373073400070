<template>
  <div>
    <Loader v-if="!isLoaded" />
    <div v-else>
      <NotFound v-if="guild.answer_status === 'NOT_FOUND'" />
      <div v-else>
        <div
          class="uk-grid uk-grid-small"
          data-uk-grid-margin
        >
          <div class="uk-width-3-4@m">
            <GeneralInformation
              :guild="guild"
            />
          </div>
          <div class="uk-width-1-4@m">
            <div
              class="uk-card uk-card-default uk-margin-bottom"
            >
              <div class="uk-card-header">
                <h3 class="uk-card-title">
                  {{ $t('discord.guild_details.stats') }}
                </h3>
              </div>
              <div class="uk-card-body uk-padding-small">
                <ul
                  class="uk-list uk-list-divider uk-text-left uk-width-expand"
                >
                  <li>
                    {{ $t('discord.guild_details.member_count') }}
                    <span class="uk-float-right">
                      {{ guild.approximate_member_count }}
                    </span>
                  </li>
                  <li>
                    {{ $t('discord.guild_details.presence_count') }}
                    <span class="uk-float-right">
                      {{ guild.approximate_presence_count }}
                    </span>
                  </li>
                  <li>
                    {{ $t('discord.guild_details.premium_count') }}
                    <span class="uk-float-right">
                      {{ guild.premium_subscription_count }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-margin-medium-top"
      >
        <ul class="uk-tab">
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'overview_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'overview_tab'
                }
              }"
            >
              {{ $t('discord.channel_details.fields_title') }}
            </router-link>
          </li>
          <li
            v-if="guild.has_channels"
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'channels_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'channels_tab'
                }
              }"
            >
              {{ $t('discord.channel_details.channels_title') }}
            </router-link>
          </li>
          <li
            v-if="guild.has_channels"
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'messages_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'messages_tab'
                }
              }"
            >
              {{ $t('discord.user_details.message_title') }}
            </router-link>
          </li>
          <li
            v-if="guild.has_channels"
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'users_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  info: $route.query.info ? $route.query.info : 'current_tab',
                  details: 'users_tab'
                }
              }"
            >
              {{ $t('discord.channel_details.user_title') }}
            </router-link>
          </li>
        </ul>
        <div :class="$route.query.details === 'channels_tab' ? '' : 'uk-card uk-card-default uk-padding-small'">
          <ul
            class="uk-switcher"
          >
            <li
              v-if="$route.query.details === 'overview_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'overview_tab'
              }"
            >
              <table class="uk-table uk-table-divider uk-table-left">
                <thead>
                  <th class="uk-table-shrink uk-text-center">
                    {{ $t('discord.guild_details.parameter') }}
                  </th>
                  <th class="uk-table-expand">
                    {{ $t('discord.guild_details.value') }}
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td class="uk-text-center uk-text-nowrap">
                      {{ $t('discord.guild_details.nsfw_level') }}
                    </td>
                    <td class="uk-text-left">
                      {{ guild.nsfw_level ? guild.nsfw_level : $t('discord.known_links.none') }}
                    </td>
                  </tr>
                  <tr>
                    <td class="uk-text-center uk-text-nowrap">
                      {{ $t('discord.guild_details.verification_level') }}
                    </td>
                    <td class="uk-text-left">
                      {{ guild.verification_level ? guild.verification_level : $t('discord.known_links.none') }}
                    </td>
                  </tr>
                  <tr v-if="guild.keywords?.length > 0">
                    <td class="uk-text-center uk-text-nowrap">
                      {{ $t('discord.guild_details.keywords') }}
                    </td>
                    <td>
                      <div class="uk-flex uk-flex-wrap">
                        <div 
                          v-for="keyword in guild.keywords"
                          :key="keyword"
                          class="uk-margin-small-right"
                        >
                          <router-link
                            :to="{ name: 'discord_guild_list', query: { search: keyword }}"
                          >
                            {{ keyword }}
                          </router-link>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="guild.features?.length > 0">
                    <td class="uk-text-center uk-text-nowrap">
                      {{ $t('discord.guild_details.features') }}
                    </td>
                    <td>
                      <div class="uk-flex uk-flex-wrap">
                        <div 
                          v-for="feature in guild.features"
                          :key="feature"
                          class="uk-margin-small-right"
                        >
                          {{ feature }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr 
                    v-if="banner" 
                    style="max-width: 50rem;"
                  >
                    <td class="uk-text-center uk-text-nowrap">
                      {{ $t('discord.guild_details.banner') }}
                    </td>
                    <td>
                      <div 
                        style="position: relative;"
                        class="cover uk-align-left"
                      >
                        <img
                          slot="preloader"
                          :src="banner.url"
                          uk-img
                        />
                        <AvatarThumbnail
                          :src="banner.url"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li
              v-if="$route.query.details === 'channels_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'channels_tab',
              }"
            >
              <ChannelsList
                :guildId="guild.id"
              />
            </li>
            <li
              v-if="$route.query.details === 'messages_tab'"
              :class="{
                'uk-active': $route.query.details === 'messages_tab',
              }"
            >
              <MessageList
                :id="guild.id"
                :type="'guild'"
              />
            </li>
            <li
              v-if="$route.query.details === 'users_tab'"
              :class="{
                'uk-animation-slide-bottom-medium uk-active': $route.query.details === 'users_tab',
              }"
            >
              <UserList
                :id="guild.id"
                :type="'guild'"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'DiscordGuildDetails',
  components: {
    Loader,
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    AvatarThumbnail: defineAsyncComponent(() => import('@/components/AvatarThumbnail')),
    GeneralInformation: defineAsyncComponent(() => import('@/components/Discord/GuildDetails/GeneralInformation')),
    ChannelsList: defineAsyncComponent(() => import('@/components/Discord/GuildDetails/ChannelsList')),
    MessageList: defineAsyncComponent(() => import('@/views/Discord/MessageList')),
    UserList: defineAsyncComponent(() => import('@/views/Discord/UserList'))
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  watch: {
    id () {
      this.update()
    }
  },
  computed: {
    ...mapGetters('discord', ['guildIcons', 'allGuilds', 'guildBanners']),
    guild () {
      return (this.id in this.allGuilds ? this.allGuilds[this.id] : null)
    },
    isLoaded () {
      return this.guild ? true : false
    },
    banner () {
      return (this.id in this.guildBanners && this.guildBanners[this.id].url ? this.guildBanners[this.id] : null)
    }
  },
  methods: {
    ...mapActions('discord', ['fetchGuildById']),
    init () {
      this.update()
    },
    async update () {
      await this.fetchGuildById(this.id)
    }
  },
  created () {
    let updateQuery = { ...this.$route.query }
    if (!updateQuery?.info) updateQuery = { ...updateQuery, info : 'current_tab' }
    else if (updateQuery.info !== 'current_tab') updateQuery.info = 'current_tab'
    if (!updateQuery?.details) updateQuery = { ...updateQuery, details : 'overview_tab' }
    this.$router.replace({
      query:  {...updateQuery },
      ...(Object.keys(this.$route.params).length && { params: this.$route.params })
    })
    this.update()
  }
}
</script>

<style scoped>
.cover:hover .with_info_button-button {
    visibility: visible;
  }
</style>

<style>
.channel-list-wrapper > :not(:first-child).channel-category-wrapper {
  margin-top: 24px;
}
</style>
