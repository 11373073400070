<template>
  <div 
    v-if="user && stats"
  >
    <h3
      v-if="stats.owner_count > 0"
      class="separator-title"
    >
      {{ $t('tgcp.user_details.current_owner') }}
    </h3>
    <ChannelListMini
      v-if="stats.owner_count > 0 || stats.former_owner_count === 0"
      :id="id"
      participantType="owner"
      participantStatus="current"
    />
    <template
      v-if="stats.former_owner_count > 0"
    >
      <h3
        class="separator-title"
      >
        {{ $t('tgcp.user_details.former_owner') }}
      </h3>
      <ChannelListMini
        :id="id"
        participantType="owner"
        participantStatus="former"
      />
    </template>
  </div>
</template>

<script>
import ChannelListMini from '@/components/TGCP/ChannelList/ChannelListMini'

export default {
  name: 'TgcpUserDetailsOwner',
  components: {
    ChannelListMini
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  computed: {
    user () {
      return this.$store.state.tgcp.users[this.id]
    },
    stats () {
      return this.user.stats
    }
  }
}
</script>

<style scoped>
.separator-title {
  margin: 12px 0 12px 0;
}
</style>
