<template>
  <div class="phone_monitoring">
    <div class="uk-card uk-card-default uk-margin-top uk-card-body uk-text-left">
      <div class="uk-card-title">
        {{ $t('osint.activity-tracker.title') }}
      </div>
      <div class="uk-flex uk-width-1-1 uk-margin-top">
        <form
          class="uk-width-1-1 uk-form-horizontal uk-flex"
          style="gap: 0.5rem"
          @submit.prevent="addAccount"
        >
          <input
            v-model.trim="name"
            class="uk-input uk-width-1-3"
            type="text"
            :placeholder="$t('osint.activity-tracker.input-placeholder')"
          /><br /><br />
          <button
            class="uk-button uk-button-primary uk-flex uk-flex-middle uk-flex-center"
            style="width:200px; height: 40px;"
            type="submit"
            :disabled="addLoader"
          >
            <span v-if="!addLoader">
              {{ $t('osint.activity-tracker.input-submit') }}
            </span>
            <Loader 
              v-else
              :ratio="1"
            />
          </button>
        </form>
      </div>
      <div class="results">
        <h3>
          {{ $t('osint.activity-tracker.table-section') }}
        </h3>
      </div>
      <div class="uk-width-1-1">
        <table class="uk-table uk-table-middle uk-margin-remove-top uk-table-striped">
          <thead>
            <tr>
              <th class="uk-width-2-5 uk-text-left">
                {{ $t('osint.activity-tracker.table-header_user') }}
              </th>
              <th class="uk-width-1-5 uk-text-center">
                {{ $t('osint.activity-tracker.table-header_status') }}
              </th>
              <th class="uk-width-1-5 uk-text-center">
                {{ $t('osint.activity-tracker.table-header_sync') }}
              </th>
              <th class="uk-width-1-5 uk-text-center">
                {{ $t('osint.activity-tracker.table-header_actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template 
              v-if="!isLoaded" 
              class="uk-flex uk-flex-center"
            >
              <tr>
                <td colspan="2">
                  <Loader :ratio="2"/>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr 
                v-for="entry of currentBatch"
                :key="entry.task_id"
              >
                <td class="uk-text-left">
                  {{ entry.task }}
                  <router-link
                    v-if="entry.tg_id"
                    uk-icon="user"
                    :uk-tooltip="$t('osint.activity-tracker.goto_user')"
                    :to="{
                      name: 'tgcp_user_detail_overview',
                      params: {
                        id: String(entry.tg_id)
                      }
                    }"
                  />
                </td>
                <td class="uk-text-center uk-text-truncate">
                  {{ entry.status.slice(0, 1).toUpperCase() + entry.status.slice(1) }}
                </td>
                <td class="uk-text-center uk-text-truncate">
                  {{ entry.updated ?? $t('tgcp.tasks.status_never_synced') }}
                </td>
                <td class="uk-text-center uk-text-truncate">
                  <button
                    class="uk-button uk-button-text"
                    uk-icon="icon: download"
                    :uk-tooltip="$t('tgcp.download')"
                    @click="downloadCsv(entry.task_id, entry.task)"
                  />
                  <button
                    class="uk-button uk-button-text uk-margin-left"
                    uk-icon="icon: trash"
                    :uk-tooltip="$t('tgcp.tasks.delete')"
                    @click="deleteAccount(entry.task_id)"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div
          v-if="!hidePagination"
        >
          <Paginate
            v-model="pagination.page"
            :page-count="lastPage"
            :click-handler="setPage"
            prev-text="<"
            next-text=">"
            container-class="uk-pagination uk-flex-center"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import { mapActions, mapGetters } from 'vuex'
import { pickSearch } from '@/utils/search'
import Paginate from '@/components/Paginate'

export default {
  name: 'ActivityTrackerOSINT',
  components: {
    Loader,
    Paginate
  },
  data () {
    return {
      name: '',
      users: [],
      addLoader: false,
      pagination: {
        total: 0,
        page: 1,
        show: 20
      }
    }
  },
  computed: {
    ...mapGetters('fetcher', [
      'isLoaded'
    ]),
    hidePagination () {
      return this.pagination.total < this.pagination.show
    },
    lastPage () {
      const lastPage = Math.floor(this.pagination.total / this.pagination.show)
      return this.pagination.total / lastPage - this.pagination.show === 0 ? lastPage : lastPage + 1
    },
    currentBatch () {
      const { users, pagination } = this
      return users.slice((pagination.page - 1) * pagination.show, (pagination.page) * pagination.show)
    }
  },
  methods: {
    ...mapActions('fetcher', [
      'addTrackActivity',
      'loadTrackedAccounts',
      'deleteTrackedAccount',
      'downloadTrackedData'
    ]),
    setPage (page) {
      this.pagination.page = page
    },
    async update () {
      this.users = await this.loadTrackedAccounts()
      this.pagination.page = 1
      this.pagination.total = this.users.length
    },
    async deleteAccount (id) {
      const action = await this.deleteTrackedAccount({ id })
      if (action) this.update()
    },
    async downloadCsv (id, task) {
      await this.downloadTrackedData({ id, task })
    },
    async addAccount () {
      if (!this.name.replace(/ /gi, '').trim()) return
      let str = this.name
      if (str.startsWith('@')) str = str.slice(1)

      this.addLoader = true
      const search = pickSearch(str)

      switch (search.type) {
          case 'phone': {
            const action = await this.addTrackActivity({ id: search.id, type: 'phone' })
            if (action) await this.update()
            this.addLoader = false
            return
          }
          case 'nickname': {

            if (!/^[A-Za-z\d_]{5,32}$/.test(str)) {
              this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('osint.activity-tracker.error.invalid'), type: 'error' })
              this.addLoader = false
              return
            }

            const action = await this.addTrackActivity({ id: search.id, type: 'nickname' })
            if (action) await this.update()
            this.addLoader = false
            return
          }
          case 'error': {
            this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('osint.activity-tracker.error.' + search.id), type: 'error' })
            break
          }
      }
    }
  },
  mounted () {
    this.update()
  }
}
</script>
