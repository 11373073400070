<template>
  <div
    v-if="channelData"
    class="uk-width-1-1"
    :class="[
      level === 0 ? 'channel-category-wrapper' : '',
      (level === 0 && $route.name === 'discord_guild_details') ? 'uk-card uk-card-small uk-card-default uk-padding-small' : ''
    ]"
  >
    <div
      v-if="$route.name === 'discord_channel_details'"
      class="uk-padding-remove uk-margin-bottom uk-link-muted uk-text-muted uk-text-left"
    >
      <span>
        <router-link :to="{ name: 'discord_guild_details', params: { id: channelData.guild_id } }">
          {{ guildName }}
        </router-link>
      </span>{{ (indirectParent ? ` / ${indirectParent.name}` : '') + (parent ? ` / ${parent.name}` : '') }}
    </div>
    <div
      :class="[
        (level !== 0 || $route.name === 'discord_channel_details') ? 'uk-card uk-card-default uk-card-small uk-padding-small' : '',
        (level !== 0 && $route.name === 'discord_guild_details') ? 'quoted uk-margin-left' : ''
      ]"
    >
      <div class="uk-flex uk-width-1-1 uk-flex-between">
        <div class="uk-flex uk-flex-column uk-width-2-3">
          <h3 
            class="uk-margin-remove uk-text-left uk-flex uk-flex-middle"
            style="gap: 4px; line-height: 1.25;"
          >
            <span 
              v-if="icon" 
              :uk-icon="icon"
              :uk-tooltip="$t(`discord.channel_details.${icon}_tooltip`)"
            />
            {{ channelData.name }}
          </h3>
          <h6 
            v-if="channelData.topic"
            class="uk-margin-remove uk-text-left uk-text-default"
          >
            {{ channelData.topic }}
          </h6>
        </div>
        <div
          v-if="!($route.name === 'discord_channel_details')"
          class=""
        >
          <div 
            v-if="channelData.id in guildLayerChannels || channelData.type === 4"
          >
            <button
              class="uk-button uk-button-default"
              @click="next = !next"
            >
              {{ next ? $t('discord.channel_details.hide_channels') : $t('discord.channel_details.show_channels') }}
            </button>
          </div>
          <div v-else>
            <router-link 
              :to="{ name: 'discord_channel_details', params: { channel: channelData.id } }"
              class="uk-button uk-button-primary"
            >
              {{ $t('discord.channel_details.open_channel') }}
            </router-link>
          </div>
        </div>
      </div>
      <div
        v-if="guildId && (channelData.id in guildLayerChannels)"
        v-show="next"
        class="uk-margin-small-top"
      >
        <ChannelsList
          :guild-id="guildId"
          :parent-id="channel"
          :level="1"
        />
      </div>
      <div 
        v-if="$route.name === 'discord_channel_details'"
        class="uk-margin-top"
      >
        <ul class="uk-tab">
          <li
            class="uk-flex"
            :class="{
              'uk-active': $route.query.details === 'messages_tab'
            }"
          >
            <router-link
              class="uk-padding-remove"
              :to="{
                query: {
                  details: 'messages_tab'
                }
              }"
            >
              {{ $t('discord.user_details.message_title') }}
            </router-link>
          </li>
        </ul>
        <ul
          class="uk-switcher"
        >
          <li
            v-if="$route.query.details === 'messages_tab'"
            :class="{
              'uk-active': $route.query.details === 'messages_tab'
            }"
          >
            <MessageList
              :id="channelData.id"
              :type="'channel'"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div 
    v-else
  >
    <Loader />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'
import Loader from '@/components/TGCP/Loader'

export default {
  name: 'DiscordChannelItem',
  components: {
    ChannelsList: defineAsyncComponent(() => import('@/components/Discord/GuildDetails/ChannelsList')),
    MessageList: defineAsyncComponent(() => import('@/views/Discord/MessageList')),
    Loader
  },
  props: {
    guildId: {
      required: false,
      type: String
    },
    channel:{
      required: true,
      type: String
    },
    level: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      next: true
    }
  },
  computed: {
    ...mapGetters('discord', ['channels', 'guildChannels', 'guildLayerChannels', 'allGuilds']),
    isParent () {
      return this.level === 0
    },
    channelData () {
      return this.channels[this.channel]
    },
    guildName () {
      const id = this.channelData ? this.channelData.guild_id : (this.guildId ?? null)
      if (!id) return null
      if (!(id in this.allGuilds)) return id
      return this.allGuilds[id].name
    },
    parent () {
      const id = this.channelData ? this.channelData.parent_id : null
      if (!id) return null
      if (!(id in this.channels)) return id
      return this.channels[id]
    },
    indirectParent () {
      const id = this.parent ? this.parent.parent_id : null
      if (!id) return null
      if (!(id in this.channels)) return id
      return this.channels[id]
    },
    icon () {
      if (!this.channelData) return null
      switch (this.channelData.type) {
          case 4: return 'list'
          case 2:
          case 13: return 'microphone'
          case 5:
          case 10: return 'comment'
          default: return null
      }
    }
  },
  methods: {
    ...mapActions('discord', ['fetchChannelById', 'fetchGuildById'])
  },
  created () {
    if (!this.$route.name === 'discord_channel_details') return
    let updateQuery = { ...this.$route.query }
    if (!updateQuery?.details) {
      updateQuery = { ...updateQuery, details : 'messages_tab' }
      this.$router.replace({
        query:  {...updateQuery },
        ...(Object.keys(this.$route.params).length && { params: this.$route.params })
      })
    }
  },
  async mounted () {
    if (!this.guildId && !this.channelData) {
      const chan = await this.fetchChannelById(this.channel)
      if (chan) {
        this.fetchGuildById(chan.guild_id)
        if (chan.parent_id) {
          const chan2 = await this.fetchChannelById(chan.parent_id)
          if (chan2 && chan2.parent_id) {
            this.fetchChannelById(chan2.parent_id)
          }
        }
      }
    }
  }
}

</script>

<style>
.quoted {
  border-left: 4px solid rgba(221, 221, 221, 0.5);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
}
</style>