import { apiRequest, getQueryString } from '@/utils/api'
import config from '@/config'
import { removeEscapeCharacters, filterFields } from '@/utils/csvExport/fieldsParse'
import Leak from '../../utils/providers/leak'
const leakProvider = new Leak()

export default {
  namespaced: true,
  state: {
    currentLeaksQuery: {},
    leaks: {},
    errors: {},
    isOverLimits: {},
    isLoaded: {
      kvsearch: true
    },
    csv: {
      ready: null,
      data: null,
      type: null,
      from: null,
      autostart: true,
      allowedFields: []
    }
  },
  getters: {
    leaksQuery: state => state.currentLeaksQuery,
    isLoaded: state => state.isLoaded,
    leakErrors: state => state.errors,
    csvReady: state => state.csv.ready,
    csvData: state => state.csv.data,
    passwords: state => state.leaks,
    isOverLimits: state => state.isOverLimits,
    csvType: state => state.csv.type,
    csvFrom: state => state.csv.from,
    csvAutoDownload: state => state.csv.autostart,
    allowedFields: state => state.csv.allowedFields
  },
  mutations: {
    setPasswords (state, { search, leaks }) {
      if (!state.leaks[search]) state.leaks[search] = []
      state.leaks[search] = leaks.items
      state.errors[search] = ''
      state.isOverLimits[search] = leaks.isOverLimit
    },
    setCurrentQuery (state, query) {
      state.currentLeaksQuery = query
    },
    setError (state, { search, error }) {
      state.errors[search] = error
    },
    startLoad: (state, payload) => {
      state.isLoaded[payload] = false
    },
    stopLoad: (state, payload) => {
      return state.isLoaded[payload] = true
    },
    csvReady: (state, percent) => {
      state.csv.ready = percent
    },
    downloadcsv: (state, { data, type, from }) => {
      if (type) state.csv.type = type
      if (from) state.csv.from = from
      state.csv.data = data
    },
    clearCSVData: state => {
      delete state.csv.data
      state.csv.data = {}
    },
    setcsvReady: state => {
      state.csv.ready = 0
    },
    setAutoDownload: (state, autostart) => {
      state.csv.autostart = autostart
    },
    allowedFields: (state, allowedFields) => {
      delete state.csv.allowedFields
      state.csv.allowedFields = allowedFields
    }
  },
  actions: {
    loadLeaks ({ commit }, query) {
      if (!query) {
        console.error('Empty query!')
        return
      }
      commit('startLoad', `kvsearch`)
      commit('setCurrentQuery', query)
      leakProvider
        .loadDataByType(query.searchType, query.searchString)
        .then(leaks => {
          commit('setPasswords', { search: query.searchString, leaks: leaks })
        })
        .catch(error => commit('setError', { search: query.searchString, error: error.message }))
        .finally(() => commit('stopLoad', `kvsearch`))
    },
    async downloadcsv ({ dispatch, commit, state }, {
      query, type, from, total, allowedFields, onePage
    }) {
      if (state.csv.ready > 0 && state.csv.ready < 100) return
      commit('clearCSVData')
      commit('csvReady', 1)
      let fullData = []
      if (onePage === 'one') {
        total = query.limit
      } else {
        total = 5000
        query.limit = 100
      }
      let errors = 0
      for (let i = 0; i < total; i += query.limit) {
        if (onePage === 'all') query.offset = i
        let queryString = getQueryString(query)
        try {
          let data = await apiRequest(`/search?${queryString}`, 'GET', {}, config.servers.leaks)
          fullData.push(...data.data.data)
        } catch {
          if (errors > 4) break
          else {
            errors++
            i -= query.limit
            continue
          }
        }
      }
      fullData = fullData.map(obj => {
        obj = removeEscapeCharacters(obj)
        obj = filterFields(obj, allowedFields)
        return obj
      })
      from = from.searchType || '' + '_' + from.searchString || '' + '_' + from.selectedIndex || ''
      commit('allowedFields', allowedFields)
      commit('csvReady', 100)
      commit('downloadcsv', {
        data: fullData,
        type: type,
        from: from
      })
    },
    setAutoDownload ({ commit }, autostart) {
      commit('setAutoDownload', autostart)
    }
  }
}
