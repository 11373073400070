// @ts-check
/**
 * @typedef {{ id: number, update: Voided, setRejected: ModifyProcess, setFulfilled: ModifyProcess, add: ModifyStrategy, continue: ModifyProcess, clear: Voided }} QueueStruct
 * @typedef {{ id: number, description: string, Strategy: Strategy, progress: Progress, payload?: ProcessPayload }} Process
 * @typedef {import('./Strategies/Base').Strategy} Strategy
 * @typedef {TxtEntry[]|object[]} ProcessPayload
 * @typedef {(Strategy: Strategy) => void} ModifyStrategy
 * @typedef {(Process: Process) => void} ModifyProcess
 * @typedef {import('./Formats/Txt').Entry} TxtEntry
 * @typedef {{entries: Entry[], progress: number}} Observable
 * @typedef {() => void} Voided
 * @typedef {string} Progress
 * @typedef {Process} Entry
 */

import Utils from './Utils'
import File from './File.js'
import { reactive } from 'vue'

/**
 * @class
 * @readonly
 * @extends {Map}
 * @implements QueueStruct
 */
class Queue extends Map {
  /**
   * @type {number}
   */
  id = 0
  /**
   * @type Observable
   */
  observable = { entries: [], progress: 0 }
  /*
   * @readonly
   * @constructor
   * */
  constructor () {
    super()
    try {
      this.observable = reactive({ entries: [], progress: 0 })
    } catch (error) {
      console.debug(error)
    }
  }
  /*
   * @readonly
   * */
  update () {
    try {
      const entries = Array.from(super.keys())
      this.observable.entries = Utils.unique(entries)
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @readonly
   * @param {Process} Process
   */
  setRejected (Process) {
    try {
      // поправить из за форыча висяки
      this.observable.entries = this.observable.entries.map(entry => {
        try {
          if (entry.id === Process.id) {
            entry.progress = 'rejected'
          }
        } catch (error) {
          console.debug(error)
        }
        return entry
      })
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @readonly
   * @param {{Strategy: Strategy, id: number}} Process
   */
  continue (Process) {
    try {
      // поправить из за форыча висяки
      this.observable.entries = this.observable.entries.map(entry => {
        try {
          if (entry.id === Process.id && entry.progress !== 'pending') {
            entry.progress = 'pending'
            const process = {
              Strategy: entry.Strategy,
              id: entry.id,
              progress: 'fulfilled',
              description: Utils.getDescription(entry.Strategy.options)
            }
            entry.Strategy.start()
              .then(payload => this.setFulfilled({ ...process, payload }))
              .catch(() => this.setRejected(process))
          }
        } catch (error) {
          console.debug(error)
        }
        return entry
      })
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @readonly
   * @param {Process} Process
   */
  setFulfilled (Process) {
    try {
      if (Process.payload !== void 0) {
        // поправить из за форыча висяки
        this.observable.entries = this.observable.entries.map(entry => {
          try {
            if (entry.id === Process.id) {
              entry.progress = 'fulfilled'
              const entries = Process.payload
              const properties = Process.Strategy.options.allowedFields
              entry.payload = Utils.selectProperties({
                properties,
                entries: entries ? entries : []
              })
            }
          } catch (error) {
            console.debug(error)
          }
          return entry
        })
        File.get(Process)
      }
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @readonly
   * @param {Strategy} Strategy
   */
  add (Strategy) {
    try {
      const process = {
        Strategy,
        id: this.id,
        progress: 'pending',
        description: Utils.getDescription(Strategy.options),
        continue: (/** @type {number} */ id) => {
          try {
            this.continue({ Strategy, id})
          } catch (error) {
            console.debug(error)
          }
        }
      }
      const next = Strategy.start()
        .then(payload => this.setFulfilled({ ...process, payload }))
        .catch(() => this.setRejected(process))
      this.id++
      super.set(process, next)
      this.update()
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @readonly
   * @param {Process} Process
   */
  stop (Process) {
    try {
      Process.Strategy.stop()
    } catch (error) {
      console.debug(error)
    }
  }
  /**
   * @readonly
   * @param {Process} Process
   */
  delete (Process) {
    try {
      const key = ([...super.keys()].find(entry => entry.id === Process))
      if (key.progress !== 'rejected') {
        key.Strategy.stop()
      }
      const isDelete = super.delete(key)
      this.update()
      return isDelete
    } catch (error) {
      console.debug(error)
    }
    return false
  }
  continueAll () {
    try {
      this.observable.entries.forEach(entry => {
        try {
          if (entry.progress === 'rejected') {
            this.continue(entry)
          }
        } catch (error) {
          console.debug(error)
        }
      })
    } catch (error) {
      console.debug(error)
    }
  }
  stopAll () {
    try {
      this.observable.entries.forEach(Process => {
        try {
          if (Process.progress !== 'rejected') {
            Process.Strategy.stop()
          }
        } catch (error) {
          console.debug(error)
        }
      })
    } catch (error) {
      console.debug(error)
    }
  }
  clearAll () {
    try {
      this.observable.entries.forEach(Process => {
        try {
          if (Process.progress !== 'rejected') {
            Process.Strategy.stop()
          }
        } catch (error) {
          console.debug(error)
        }
      })
      const isClear = super.clear()
      this.update()
      return isClear
    } catch (error) {
      console.debug(error)
    }
    return false
  }
}

export default new Queue()
