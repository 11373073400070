<template>
  <div>
    <Loader v-if="!isLoaded['hashtag_' + id]"/>
    <div v-else-if="allHashtags[id]">
      <h3>
        {{ $t('twitter.hashtags.hashtag-details') }}
      </h3>
      <h2>
        #{{ allHashtags[id].value }}
      </h2>
    </div>
    <NotFound v-else/>
    <div class="uk-margin-medium-top">
      <ul class="uk-tab">
        <li
          class="uk-flex"
          :class="{
            'uk-active': $route.query.tab === 'overview_tab'
          }"
        >
          <router-link
            class="uk-padding-remove"
            :to="{
              query: {
                tab: 'overview_tab'
              }
            }"
          >
            {{ $t('twitter.hashtags.overview') }}
          </router-link>
        </li>
        <li
          class="uk-flex"
          :class="{
            'uk-active': $route.query.tab === 'users_tab',
            'uk-disabled': !hash || !hash.users_count
          }"
        >
          <router-link
            class="uk-padding-remove"
            :to="{
              query: {
                tab: 'users_tab'
              }
            }"
          >
            {{ $t('twitter.hashtags.users') }}
            <span class="uk-badge uk-margin-small-left">
              {{ hash?.users_count ?? 0 }}
            </span>
          </router-link>
        </li>
        <li
          class="uk-flex"
          :class="{
            'uk-active': $route.query.tab === 'messages_tab',
            'uk-disabled': !hash || !hash.posts_count
          }"
        >
          <router-link
            class="uk-padding-remove"
            :to="{
              query: {
                tab: 'messages_tab'
              }
            }"
          >
            {{ $t('tiktok.hashtags.posts') }}
            <span class="uk-badge uk-margin-small-left">
              {{ hash?.posts_count ?? 0 }}
            </span>
          </router-link>
        </li>
        <li
          class="uk-flex"
          :class="{
            'uk-active': $route.query.tab === 'together_tab'
          }"
        >
          <router-link
            class="uk-padding-remove"
            :to="{
              query: {
                tab: 'together_tab'
              }
            }"
          >
            {{ $t('twitter.hashtags.tags-used-together') }}
          </router-link>
        </li>
      </ul>
      <ul class="uk-switcher">
        <li
          :class="{
            'uk-animation-slide-bottom-medium uk-active': $route.query.tab === 'overview_tab'
          }"
        >
          <HashtagLineGraph
            :id="id"
            type="day"
          />
          <HashtagLineGraph
            :id="id"
            type="week"
          />
          <HashtagLineGraph
            :id="id"
            type="year"
          />
        </li>
        <li
          :class="{
            'uk-animation-slide-bottom-medium uk-active': $route.query.tab === 'users_tab'
          }"
        >
          <UserList
            v-if="$route.query.tab === 'users_tab'"
            :id="id"
            type="hashtag"
          />
        </li>
        <li
          :class="{
            'uk-animation-slide-bottom-medium uk-active': $route.query.tab === 'messages_tab'
          }"
        >
          <MessageList
            v-if="$route.query.tab === 'messages_tab'"
            :id="id"
            type="hashtag"
          />
        </li>
        <li
          :class="{
            'uk-animation-slide-bottom-medium uk-active': $route.query.tab === 'together_tab'
          }"
        >
          <HashtagList
            v-if="$route.query.tab === 'together_tab'"
            :id="id"
            type="together"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/TGCP/Loader'
import UserList from '@/views/Tiktok/UserList'
import MessageList from '@/views/Tiktok/MessageList'
import HashtagList from '@/views/Tiktok/HashtagList'
import HashtagLineGraph from '@/components/Tiktok/Hashtags/LineGraph'
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  name: 'TiktokHashtagDetails',
  components: {
    NotFound: defineAsyncComponent(() => import('@/components/TGCP/NotFound')),
    Loader,
    UserList,
    MessageList,
    HashtagList,
    HashtagLineGraph
  },
  props: {
    id: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      activatedTabs: this.$route.query.tab || ['overview_tab']
    }
  },
  watch: {
    async id () {
      await this.update()
    }
  },
  computed: {
    ...mapGetters('tiktok', [
      'allHashtags',
      'isLoaded'
    ]),
    hash () {
      return this.allHashtags[this.id]
    }
  },
  methods: {
    ...mapActions('tiktok', [
      'fetchHashtagById'
    ]),
    activateTab (tab) {
      !this.activatedTabs.includes(tab) &&
        this.activatedTabs.push(tab)
    },
    handleError () {
      this.$notify({ group: 'general', title: this.$t('notifications.error'), text: this.$t('cases.error'), type: 'error' })
    },
    async update () {
      try {
        await this.fetchHashtagById(this.id)
      } catch (err) {
        this.handleError(err)
      }
    }
  },
  created () {
    let updateQuery = { ...this.$route.query }
    if (!updateQuery?.tab) updateQuery = { ...updateQuery, tab : 'overview_tab' }
    this.$router.replace({
      query:  {...updateQuery },
      ...(Object.keys(this.$route.params).length && { params: this.$route.params })
    })
    this.update()
  }
}
</script>
