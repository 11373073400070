<template>
  <div>
    <button
      v-show="entries.length"
      class="uk-button uk-button-default uk-background-default uk-button-small uk-position-center-right uk-position-fixed my-uk-button"
      type="button"
      uk-toggle="target: #export-offcanvas"
    >
      <span uk-icon="chevron-left" />
    </button>
    <div
      ref="exportOffcanvas"
      id="export-offcanvas"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div class="uk-offcanvas-bar uk-background-default">
        <button
          class="uk-offcanvas-close my-uk-close"
          type="button"
          uk-close
          :uk-tooltip="$t('csv.close')"
        />
        <button
          :disabled="!mayStopAll"
          class="uk-button uk-button-default uk-button-small my-uk-stop"
          :uk-tooltip="$t('csv.stop')"
          @click="stopAll"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="-64 0 512 512"
          >
            <path 
              fill="currentColor"
              d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
            />
          </svg>
        </button>
        <button
          :disabled="!mayContinueAll"
          class="uk-button uk-button-default uk-button-small my-uk-continue"
          :uk-tooltip="$t('csv.continue')"
          @click="continueAll"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="-64 0 512 512"
          >
            <path 
              fill="currentColor"
              d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
            />
          </svg>
        </button>
        <button
          class="uk-button uk-button-default uk-button-small my-uk-clear"
          :uk-tooltip="$t('csv.clear')"
          @click="clearAll"
        >
          <span uk-icon="trash" />
        </button>
        <h3
          v-text="$t('Downloads')"
          class="my-offcanvas-title"
        />
        <NotificationCard
          v-for="entry in entries"
          :key="entry.id"
          :entry="entry"
          @setCurrent="setCurrent"
        />
      </div>
    </div>
    <div
      v-if="current"
      id="export-preview"
      class="uk-modal-full"
      uk-modal
    >
      <div class="uk-modal-dialog my-min-height">
        <button
          class="uk-modal-close-full uk-close-large"
          type="button"
          style="position: sticky; float: right;"
          uk-close
        />
        <div>
          <div class="uk-padding-large">
            <h1
              v-if="current.description"
            >
              {{ current.description.replaceAll('_', ' ') }}
            </h1>
            <div class="uk-overflow-auto">
              <table class="uk-table uk-table-small uk-table-divider">
                <thead>
                  <tr>
                    <th
                      v-for="key in keys"
                      :key="key"
                    >
                      {{ key }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(payload, index) in current.payload"
                    :key="index + '_' + getURN(payload) + '_payloadRow'"
                  >
                    <td
                      v-for="(value, index) in Object.values(payload)"
                      :key="index + '_' + value + '_payloadCell'"
                    >
                      {{ value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Queue from '../Queue'
import UIkit from 'uikit'
import NotificationCard from './NotificationCard.vue'

export default {
  name: 'ExportNotification',
  components: {
    NotificationCard
  },
  data () {
    return {
      current: {}
    }
  },
  watch: {
    entries: {
      immediate: false,
      deep: true,
      handler (to, from) {
        try {
          if (to.length && from.length === 0) {
            UIkit.offcanvas(this.$refs.exportOffcanvas).show()
          }
        } catch (error) {
          console.debug(error)
        }
      }
    }
  },
  computed: {
    keys () {
      try {
        if (this.current && this.current.payload && this.current.payload.length) {
          const entries = this.current.payload
          let properties = []
          for (const entry of entries.slice(0,100)) {
            const keys = Object.keys(entry)
            properties.push(...keys)
          }
          properties = [...new Set(properties)]
          return properties
        } else if (this.current?.Strategy?.options?.data?.allowedFields && this.current.Strategy.options.data.allowedFields.length > 0) {
          return [...this.current.Strategy.options.data.allowedFields]
        }
      } catch (error) {
        console.debug(error)
      }
      return []
    },
    entries () {
      try {
        return Queue.observable.entries
      } catch (error) {
        console.debug(error)
      }
      return []
    },
    mayStopAll () {
      try {
        return this.entries.length && this.entries.some(entry => {
          const progress = this.progress(entry.progress)
          const isRejected = this.isRejected(entry.progress)
          return progress < 100 && !isRejected
        })
      } catch (error) {
        console.debug(error)
      }
      return false
    },
    mayContinueAll () {
      try {
        return this.entries.length && this.entries.some(entry => {
          const progress = this.progress(entry.progress)
          const isRejected = this.isRejected(entry.progress)
          return progress === 100 || isRejected
        })
      } catch (error) {
        console.debug(error)
      }
      return false
    }
  },
  methods: {
    isRejected (progress) {
      try {
        return progress === 'rejected'
      } catch (error) {
        console.debug(error)
      }
    },
    progress (progress) {
      try {
        switch (progress) {
            case 'fulfilled':
              return 100
            case 'rejected':
              return 0
            case 'pending':
              return 50
            default:
              return 0
        }
      } catch (error) {
        console.debug(error)
      }
      return 0
    },
    setCurrent (current) {
      try {
        this.current = current
      } catch (error) {
        console.debug(error)
      }
    },
    getURN (value) {
      return JSON.stringify(value)
    },
    stop: process => Queue.stop(process),
    stopAll: () => Queue.stopAll(),
    continueAll: () => Queue.continueAll(),
    clearAll: () => Queue.clearAll()
  }
}
</script>
<style scoped>
.list-enter-active,
.list-leave-active {
  transition: opacity 1s;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.my-uk-close,
.my-uk-clear {
  color: #999 !important;
}

.my-uk-button {
  padding-left: 5px;
  padding-right: 5px;
}

.my-offcanvas-title {
  color: #999 !important;
}

.my-uk-clear {
  position: absolute;
  z-index: 1000;
  top: 10px;
  left: 20px;
  padding: 5px;
}

.my-uk-continue {
  color: #999 !important;
  position: absolute;
  z-index: 1000;
  top: 10px;
  left: 52px;
  padding: 5px;
  width: 32px;
  height: 40px;
}

.my-uk-stop {
  color: #999 !important;
  position: absolute;
  z-index: 1000;
  top: 10px;
  left: 84px;
  padding: 5px;
  width: 32px;
  height: 40px;
}

.my-min-height {
  min-height: 100vmax
}
</style>
